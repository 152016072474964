import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
// import OldHomeView from '../views/OldHomeView.vue';
// import AboutView from '../views/AboutView.vue'
import MyHomePage from '../views/MyHomePage.vue';
// import MyProfile from '../views/MyProfile.vue'
// import FleetDashboard from '../views/FleetDashboard.vue'
// import ManageSubscription from '../views/ManageSubscription.vue'
import NotFound from '../views/NotFound.vue';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        // {
        //     path: '/',
        //     name: 'home',
        //     component: HomeView
        // },
        {
            path: '/hero',
            name: 'hero',
            component: HomeView
        },
        {
            // path: '/my-home-page',
            path: '/',
            name: 'MyHomePage',
            component: MyHomePage
        },
        // {
        //     // path: '/my-home-page',
        //     path: '/old-home-view',
        //     name: 'OldHomeView',
        //     component: OldHomeView
        // },
        { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound },
        {
            path: '/about',
            name: 'about',
            component: () => import('../views/AboutView.vue'),
            meta: { requiresAuth: true },
            props: true
        },
        { path: '/authenticate', name: 'authenticate', component: () => import('../backend/Authenticate.vue') },
        {
            path: '/my-profile',
            name: 'MyProfile',
            component: () => import('../views/MyProfile.vue')
        },
        {
            path: '/my-api-access',
            name: 'MyApiAccess',
            component: () => import('../views/profile/MyApiAccess.vue')
        },
        {
            path: '/supplier-dashboard',
            name: 'SupplierDashboard',
            component: () => import('../components/suppliers/SupplierDashboard.vue'),
            props: true
        },
        // {
        //     path: '/supplier-information',
        //     name: 'SupplierInformation',
        //     component: () => import('../components/suppliers/SupplierInformation.vue'),
        //     props: true
        // },
        {
            path: '/supplier-asset-group',
            name: 'SupplierAssetGroup',
            component: () => import('../components/suppliers/SupplierAssetGroup.vue'),
            props: true
        },
        {
            path: '/scheduled-trips',
            name: 'ScheduledTrips',
            component: () => import('../components/vessels/trips/ScheduledTrips.vue'),
            props: true
        },

        // fleet dashboard and sub screens =================================================================
        {
            path: '/fleet-dashboard',
            name: 'FleetDashboard',
            component: () => import('../components/company/FleetDashboard.vue'),
            props: true
        },
        {
            path: '/fleet-dash-all-widgets',
            name: 'FleetDashAllWidgets',
            component: () => import('../components/company/dash-screens/FleetDashAllWidgets.vue'),
            props: true
        },
        {
            path: '/fleet-team-dashboard',
            name: 'FleetTeamDashboard',
            component: () => import('../components/company/dash-screens/FleetTeamDashboard.vue'),
            props: true
        },
        {
            path: '/fleet-asset-dashboard',
            name: 'FleetAssetDashboard',
            component: () => import('../components/company/dash-screens/FleetAssetDashboard.vue'),
            props: true
        },
        {
            path: '/fleet-profile',
            name: 'FleetProfile',
            component: () => import('../components/company/FleetProfile.vue'),
            props: true
        },
        {
            path: '/fleet-level-content',
            name: 'FleetLevelContent',
            component: () => import('../components/company/FleetLevelContent.vue')
        },
        {
            path: '/style-guide',
            name: 'StyleGuide',
            component: () => import('../views/StyleGuide.vue')
        },
        {
            path: '/test-features',
            name: 'TestFeatures',
            component: () => import('../views/TestFeatures.vue')
        },
        {
            path: '/fleet-roles',
            name: 'FleetRoles',
            component: () => import('../components/company/FleetRoles.vue'),
            props: true
        },
        {
            path: '/fleet-billing',
            name: 'FleetBilling',
            component: () => import('../components/company/FleetBilling.vue')
        },
        {
            path: '/fleet-team',
            name: 'FleetTeam',
            component: () => import('../components/company/FleetTeam.vue')
        },
        {
            path: '/company-crew-profile',
            name: 'CompanyCrewProfile',
            component: () => import('../components/company/CompanyCrewProfile.vue')
        },

        {
            path: '/company-risk-assessment',
            name: 'CompanyRiskAssessment',
            component: () => import('../components/company/CompanyRiskAssessment.vue'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/company-hazards',
            name: 'CompanyHazards',
            component: () => import('../components/company/CompanyHazards.vue')
        },

        {
            path: '/company-contacts',
            name: 'CompanyContacts',
            component: () => import('../components/company/CompanyContacts.vue')
        },
        {
            path: '/company-policies',
            name: 'CompanyPolicies',
            component: () => import('../components/company/CompanyPolicies.vue')
        },
        {
            path: '/company-sops',
            name: 'CompanySops',
            component: () => import('../components/company/CompanySops.vue')
        },
        { path: '/company-eops', name: 'CompanyEops', component: () => import('../components/company/CompanyEops.vue') },
        {
            path: '/fleet-startup-checklists',
            name: 'FleetStartupChecklists',
            component: () => import('../components/company/FleetStartupChecklists.vue')
        },
        {
            path: '/fleet-shutdown-checklists',
            name: 'FleetShutdownChecklists',
            component: () => import('../components/company/FleetShutdownChecklists.vue')
        },
        {
            path: '/fleet-safety-briefings',
            name: 'FleetSafetyBriefings',
            component: () => import('../components/company/FleetSafetyBriefings.vue')
        },
        {
            path: '/fleet-induction-checklists',
            name: 'FleetInductionChecklists',
            component: () => import('../components/company/FleetInductionChecklists.vue')
        },
        {
            path: '/fleet-maintenance-checklists',
            name: 'FleetMaintenanceChecklists',
            component: () => import('../components/company/FleetMaintenanceChecklists.vue')
        },
        {
            path: '/fleet-custom-forms',
            name: 'FleetCustomLogs',
            component: () => import('../components/company/FleetCustomLogs.vue')
        },
        {
            path: '/edit-fleet-custom-form',
            name: 'EditFleetCustomLog',
            component: () => import('../components/company/EditFleetCustomLog.vue'),
            props: true
        },
        {
            path: '/fleet-important-documents',
            name: 'FleetImportantDocuments',
            component: () => import('../components/company/FleetImportantDocuments.vue')
        },

        {
            path: '/bulk-update-fleet-items',
            name: 'BulkUpdateFleetItems',
            component: () => import('../components/company/BulkUpdateFleetItems.vue'),
            meta: { requiresAuth: true },
            props: true
        },

        /// vessel sub pages =====================================
        {
            path: '/key-asset-information',
            name: 'KeyAssetInformation',
            component: () => import('../components/vessel-pages/KeyAssetInformation.vue')
        },
        {
            path: '/asset-general-settings',
            name: 'AssetGeneralSettings',
            component: () => import('../components/vessel-pages/AssetGeneralSettings.vue')
        },
        {
            path: '/asset-specifications',
            name: 'AssetSpecifications',
            component: () => import('../components/vessel-pages/AssetSpecifications.vue')
        },
        {
            path: '/asset-uploads',
            name: 'AssetUploads',
            component: () => import('../components/vessel-pages/AssetUploads.vue')
        },
        {
            path: '/asset-events-&-reminders',
            name: 'AssetEventsReminders',
            component: () => import('../components/vessel-pages/AssetEventsAndReminders.vue'),
            props: true
        },
        {
            path: '/asset-log-sequences',
            name: 'AssetLogSequences',
            component: () => import('../components/vessel-pages/AssetLogSequences.vue')
        },
        {
            path: '/manage-asset',
            name: 'ManageAsset',
            component: () => import('../views/ManageAsset.vue')
        },
        {
            path: '/manage-subscription',
            name: 'ManageSubscription',
            component: () => import('../views/ManageSubscription.vue')
        },
        {
            path: '/testing-subscriptions',
            name: 'TestingSubscriptions',
            component: () => import('../views/TestingSubscriptions.vue')
        },
        {
            path: '/sidebar-testing',
            name: 'SidebarTesting',
            component: () => import('../components/vessel-pages/SidebarTesting.vue')
        },

        { path: '/testing-dropzone', name: 'TestingDropzone', component: () => import('../views/TestingDropzone.vue') },

        { path: '/authenticate', name: 'Authenticate', component: () => import('../backend/Authenticate.vue'), props: true },

        {
            path: '/vessel-overview',
            name: 'VesselOverview',
            component: () => import('../components/vessel-pages/VesselOverview.vue')
        },
        {
            path: '/vessel-checklists',
            name: 'VesselChecklists',
            component: () => import('../components/vessel-pages/VesselChecklists.vue')
        },
        {
            path: '/fleet-assets',
            name: 'FleetAssets',
            component: () => import('../components/company/FleetAssets.vue')
        },

        {
            path: '/sms-snapshot',
            name: 'SmsSnapshot',
            component: () => import('../components/vessel-pages/SmsSnapshot.vue')
        },

        {
            path: '/fleet-builder',
            name: 'FleetBuilder',
            component: () => import('../components/admin/FleetBuilder.vue')
        },

        {
            path: '/sms-builder',
            name: 'SmsBuilder',
            component: () => import('../components/admin/SmsBuilder.vue')
        },

        {
            path: '/shared-sms',
            name: 'SharedSms',
            component: () => import('../components/vessels/safety/SharedSms.vue')
        },

        {
            path: '/emergency-procedures',
            name: 'EmergencyProcedures',
            component: () => import('../components/vessels/safety/EmergencyProcedures.vue')
        },
        {
            path: '/hazards',
            name: 'Hazards',
            component: () => import('../components/vessels/safety/Hazards.vue')
        },
        {
            path: '/operational-procedures',
            name: 'OperationalProcedures',
            component: () => import('../components/vessels/safety/OperationalProcedures.vue')
        },
        {
            path: '/vessel-policies',
            name: 'VesselPolicies',
            component: () => import('../components/vessels/safety/VesselPolicies.vue')
        },
        {
            path: '/emergency-contacts',
            name: 'EmergencyContacts',
            component: () => import('../components/vessels/safety/EmergencyContacts.vue')
        },
        {
            path: '/startup-checklists',
            name: 'StartupChecklists',
            component: () => import('../components/vessels/safety/StartupChecklists.vue')
        },
        {
            path: '/shutdown-checklists',
            name: 'ShutdownChecklists',
            component: () => import('../components/vessels/safety/ShutdownChecklists.vue')
        },
        {
            path: '/defect-register',
            name: 'DefectRegister',
            component: () => import('../components/vessel-pages/DefectRegister.vue')
        },
        {
            path: '/asset-plant-and-equipment',
            name: 'VesselPlantAndEquipment',
            component: () => import('../components/vessel-pages/AssetPlantAndEquipment.vue')
        },
        {
            path: '/asset-inventory',
            name: 'AssetInventory',
            component: () => import('../components/vessel-pages/AssetInventory.vue')
        },
        {
            path: '/asset-fluids-and-tanks',
            name: 'AssetFluidsAndTanks',
            component: () => import('../components/vessel-pages/AssetFluidsAndTanks.vue')
        },
        {
            path: '/asset-activity',
            name: 'AssetActivity',
            component: () => import('../components/vessel-pages/AssetActivity.vue')
        },
        {
            path: '/completed-trips',
            name: 'CompletedTrips',
            component: () => import('../components/vessel-pages/CompletedTrips.vue')
        },
        {
            path: '/view-completed-trip',
            name: 'ViewCompletedTrip',
            component: () => import('../components/vessel-pages/ViewCompletedTrip.vue'),
            props: true
        },
        {
            path: '/ships-logs',
            name: 'ShipsLogs',
            component: () => import('../components/vessel-pages/ShipsLogs.vue')
        },
        {
            path: '/edit-custom-form',
            name: 'EditCustomLog',
            component: () => import('../components/vessel-pages/EditCustomLog.vue'),
            props: true
        },

        {
            path: '/maintenance-checklists',
            name: 'MaintenanceChecklists',
            component: () => import('../components/vessels/safety/MaintenanceChecklists.vue')
        },
        {
            path: '/safety-briefings',
            name: 'SafetyBriefings',
            component: () => import('../components/vessels/safety/SafetyBriefings.vue')
        },
        {
            path: '/induction-checklists',
            name: 'InductionChecklists',
            component: () => import('../components/vessels/safety/InductionChecklists.vue')
        },
        {
            path: '/drills-&-training',
            name: 'DrillsAndTraining',
            component: () => import('../components/vessel-pages/DrillsAndTraining.vue')
        },
        {
            path: '/vessel-risk-assessment',
            name: 'VesselRiskAssessment',
            component: () => import('../components/vessels/safety/VesselRiskAssessment.vue'),
            props: true
        },

        {
            path: '/asset-roles',
            name: 'AssetRoles',
            component: () => import('../components/vessels/people/AssetRoles.vue')
        },

        {
            path: '/asset-statements',
            name: 'AssetStatements',
            component: () => import('../components/vessel-pages/AssetStatements.vue')
        },

        {
            path: '/manage-asset-team',
            name: 'ManageAssetTeam',
            component: () => import('../components/vessels/people/ManageAssetTeam.vue')
        },

        {
            path: '/mariner-profile',
            name: 'MarinerProfile',
            meta: { requiresAuth: true },
            component: () => import('../views/profile/MarinerProfile.vue'),
            props: true
        },
        {
            path: '/archived-content',
            name: 'ArchivedContent',
            meta: { requiresAuth: true },
            component: () => import('../components/company/ArchivedContent.vue'),
            props: true
        },
        {
            path: '/add-fleet-team-member',
            name: 'AddFleetTeamMember',
            meta: { requiresAuth: true },
            component: () => import('../components/company/AddFleetTeamMember.vue'),
            props: true
        },
        {
            path: '/fleet-message-history',
            name: 'FleetMessageHistory',
            meta: { requiresAuth: true },
            component: () => import('../components/company/FleetMessageHistory.vue'),
            props: true
        },
        {
            path: '/past-custom-logs',
            name: 'PastCustomLogs',
            meta: { requiresAuth: true },
            component: () => import('../components/vessels/history/PastCustomLogs.vue'),
            props: true
        },
        {
            path: '/view-custom-log',
            name: 'ViewCustomLog',
            meta: { requiresAuth: true },
            component: () => import('../components/vessels/history/ViewCustomLog.vue'),
            props: true
        },
        {
            path: '/fleet-qualification-matrix',
            name: 'FleetQualificationMatrix',
            meta: { requiresAuth: true },
            component: () => import('../components/company/FleetQualificationMatrix.vue'),
            props: true
        },

        {
            path: '/all-crew-schedules',
            name: 'AllCrewSchedules',
            meta: { requiresAuth: true },
            component: () => import('../components/vessels/people/AllCrewSchedules.vue'),
            props: true
        },
        {
            path: '/all-fleet-reports',
            name: 'FleetAllReports',
            meta: { requiresAuth: true },
            component: () => import('../components/company/FleetAllReports.vue'),
            props: true
        },
        {
            path: '/fleet-report-trips',
            name: 'FleetReportTrips',
            meta: { requiresAuth: true },
            component: () => import('../components/company/reports/FleetReportTrips.vue'),
            props: true
        },
        {
            path: '/fleet-suppliers',
            name: 'FleetSuppliers',
            meta: { requiresAuth: true },
            component: () => import('../components/company/FleetSuppliers.vue'),
            props: true
        },
        {
            path: '/manage-billing',
            name: 'ManageBilling',
            meta: { requiresAuth: true },
            component: () => import('../components/company/ManageBilling.vue'),
            props: true
        },
        {
            path: '/fleet-workflows',
            name: 'FleetWorkflows',
            meta: { requiresAuth: true },
            component: () => import('../components/company/FleetWorkflows.vue'),
            props: true
        },
        {
            path: '/offshoresms-services',
            name: 'OffshoreSmsServices',
            meta: { requiresAuth: true },
            component: () => import('../components/company/OffshoreSmsServices.vue'),
            props: true
        },
        {
            path: '/fleet-expense-logs',
            name: 'FleetExpenseLogs',
            meta: { requiresAuth: true },
            component: () => import('../components/company/FleetExpenseLogs.vue'),
            props: true
        },
        {
            path: '/fleet-all-audits',
            name: 'FleetAllAudits',
            meta: { requiresAuth: true },
            component: () => import('../components/company/FleetAllAudits.vue'),
            props: true
        },
        {
            path: '/fleet-alerts-info',
            name: 'FleetAlertsInfo',
            meta: { requiresAuth: true },
            component: () => import('../components/company/FleetAlertsInfo.vue'),
            props: true
        },
        {
            path: '/fleet-api-library',
            name: 'FleetApiLibrary',
            meta: { requiresAuth: true },
            component: () => import('../components/company/FleetApiLibrary.vue'),
            props: true
        },

        {
            path: '/asset-inductions-matrix',
            name: 'AssetInductionsMatrix',
            meta: { requiresAuth: true },
            component: () => import('../components/vessels/people/AssetInductionsMatrix.vue'),
            props: true
        },

        {
            path: '/all-crew-inductions',
            name: 'AllCrewInductions',
            meta: { requiresAuth: true },
            component: () => import('../components/vessels/people/AllCrewInductions.vue'),
            props: true
        },

        {
            path: '/all-asset-inductions',
            name: 'AllAssetInductions',
            meta: { requiresAuth: true },
            component: () => import('../components/vessels/people/AllAssetInductions.vue'),
            props: true
        },

        {
            path: '/all-asset-self-inductions',
            name: 'AllAssetSelfInductions',
            meta: { requiresAuth: true },
            component: () => import('../components/vessels/people/AllAssetSelfInductions.vue'),
            props: true
        },

        {
            path: '/fleet-team-messaging',
            name: 'FleetTeamMessaging',
            meta: { requiresAuth: true },
            component: () => import('../components/company/people/FleetTeamMessaging.vue'),
            props: true
        },

        {
            path: '/system-notifications',
            name: 'SystemNotifications',
            meta: { requiresAuth: true },
            component: () => import('../components/company/SystemNotifications.vue'),
            props: true
        },

        {
            path: '/archived-system-notifications',
            name: 'ArchivedSystemNotifications',
            meta: { requiresAuth: true },
            component: () => import('../components/company/ArchivedSystemNotifications.vue'),
            props: true
        },

        {
            path: '/fleet-notice-board',
            name: 'FleetNoticeBoard',
            meta: { requiresAuth: true },
            component: () => import('../components/company/FleetNoticeBoard.vue'),
            props: true
        },

        {
            path: '/archived-fleet-notice-board',
            name: 'ArchivedFleetNoticeBoard',
            meta: { requiresAuth: true },
            component: () => import('../components/company/ArchivedFleetNoticeBoard.vue'),
            props: true
        },

        {
            path: '/support',
            name: 'Support',
            meta: { requiresAuth: true },
            component: () => import('../views/profile/Support.vue'),
            props: true
        }
    ],
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
                top: 80
            };
        }
    }
});

export default router;
