<template>
    <div class="round-button" @click="proceed()" :class="{ inverted: inverted, horizontal: isHorizontal }">
        <div class="rb-icon">
            <!-- <div class="rb-icon-box" :class="[{ inverted: colorOn }, theme, { disabled: !access }]"><font-awesome-icon :icon="icon" /></div> -->
            <span><font-awesome-icon :icon="icon" /></span>
        </div>
        <div class="rb-text">
            <div class="rb-title">{{ title }}</div>
            <div class="rb-subtitle">{{ subtitle }}</div>
        </div>
    </div>
</template>

<script>
import { currentDataStore } from '@/stores/currentdata';

export default {
    setup() {
        const current_data = currentDataStore();
        return {
            current_data: current_data
        };
    },
    props: {
        path: String,
        theme: String,
        title: String,
        subtitle: String,
        inverted: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: 'globe'
        },
        fontSize: {
            type: String,
            default: '50px'
        },
        color: {
            type: String,
            default: 'cadetblue'
        },
        iconSize: {
            type: String,
            default: '150px'
        },
        borderSize: {
            type: String,
            default: '10px'
        },
        isHorizontal: {
            type: Boolean,
            default: false
        },
        preventClick: Boolean
    },
    components: {},
    mounted() {},
    computed: {
        // cssVars() {
        //     return {
        //         '--color': this.color,
        //         '--border-color': this.color,
        //         '--size': this.size
        //         // '--height': this.height + 'px'
        //     };
        // }
    },
    methods: {
        close() {},

        proceed() {
            let self = this;
            if (this.path && !this.preventClick) {
                if (this.path == '/') return;
                this.$router.push({
                    path: self.path
                });
            } else {
                let result = {};
                this.$emit('action', result);
            }
        },

        manageSubscription() {
            console.log('Managing subscription');
            this.close();
        }
    },
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
.round-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    opacity: 1;
    transition: 0.3s;
    margin-bottom: 2rem;
    max-width: 100%;
    .rb-icon {
        border: v-bind(borderSize) solid v-bind(color);
        border-radius: 50%;
        width: v-bind(iconSize);
        height: v-bind(iconSize);
        min-width: v-bind(iconSize);
        min-height: v-bind(iconSize);
        display: flex;
        align-items: center;
        justify-content: center;
        color: v-bind(color);
        margin-bottom: 12px;
        transition: 0.3s;
        span {
            font-size: v-bind(fontSize);
        }
    }
    .rb-text {
        // max-width: 200px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        .rb-title {
            font-family: 'Montserrat', sans-serif;
            font-weight: bold;
            font-size: 18px;
            // text-align: center;
            color: v-bind(color);
        }
        .rb-subtitle {
            // text-align: center;
        }
    }
    &:hover {
        // opacity: 0.7;
        .rb-icon {
            scale: 1.05;
        }
    }
    &.inverted {
        .rb-icon {
            background: v-bind(color);
            color: #fff;
        }
    }
    &.horizontal {
        flex-direction: row;
        margin-bottom: 1rem;
        .rb-text {
            text-align: left;
            margin-left: 1rem;
            align-items: flex-start;
        }
    }
}
@media screen and (max-width: 479px) {
    .round-button {
        &.horizontal {
            flex-direction: column;
            .rb-text {
                text-align: center;
                margin-left: 0;
                margin-bottom: 1rem;
                align-items: center;
            }
        }
    }
}
</style>
