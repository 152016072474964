<script>
import TheWelcome from '@/components/TheWelcome.vue'
import { getAuth, onAuthStateChanged } from "firebase/auth"

export default {

    components: {
        TheWelcome
    },
    mounted() {
      onAuthStateChanged(getAuth(), (user) => {
        if (user) {
            this.isLoggedIn = true;
        } else {
            this.isLoggedIn = false;
        }
      })
    },

    data() {
      return {
          isLoggedIn: false,

      }
    },

    methods: {
        
    }


}


</script>

<template>

  <div class="">

    <div class="grid-wrapper center two">

      <div class="flex-vert centered">
        <h4>Oops! Page not found..</h4>
        <div>
          <img class="db-hero-image" src="@/assets/img/general/team.jpg" alt="Welcome to Offshore SMS">
        </div>
      </div> 
      <div class="flex-vert main-side">
        <TheWelcome />
      </div>

    </div>

  </div>

</template>


<style lang="scss" scoped>
h4 {
    margin-bottom: 2rem;
}
.db-hero-image {
  width: 100%;
  max-width: 250px;
}

</style>
