<template>
    <MobileNav />

    <div class="page-wrapper">
        <div class="header-image">
            <div class="hero-content">
                <h1>Tools for Commercial Marine Businesses</h1>
                <p>
                    Easy to use, completely paperless and free to get started. OffshoreSMS helps you manage safety and compliance on your commercial
                    vessels.
                </p>
                <div class="buttons">
                    <RouterLink class="button is-primary is-medium" to="/">Get Started</RouterLink>
                    <!-- <RouterLink class="button is-danger is-medium" to="testing-dropzone">Test Dropzone</RouterLink> -->
                </div>
                <!-- <div class="buttons">
          <button class="button is-success" @click="showLoader()">loader.show()</button>
          <button class="button is-danger float" @click="hideLoader()">loader.hide()</button>
        </div> -->
            </div>
        </div>

        <div class="spacer s30"></div>

        <div class="tab-wrapper">
            <div :class="{ selected: tab == '1' }" @click="(tab = '1'), setTab('1')" class="round-tab">
                <span class="icon"><font-awesome-icon icon="ship" /></span>
                <span class="text">I need an SMS</span>
            </div>

            <div :class="{ selected: tab == '2' }" @click="(tab = '2'), setTab('2')" class="round-tab">
                <span class="icon"><font-awesome-icon icon="ship" /></span>
                <span class="text">I Manage a Fleet</span>
            </div>
        </div>

        <div class="tab-content-wrapper one" v-if="tab == '1'">
            <div class="tc-image">
                <img class="db-hero-image" src="@/assets/img/general/devices-1.png" alt="Devices on OffshoreSMS" />
            </div>
            <div class="tc-content">
                <h4>How to get an SMS, fast!</h4>
                <div class="home-steps-list">
                    <div class="step-item">
                        <div class="counter"><span>1</span></div>
                        <div class="details">
                            Create a Free user profile on OffshoreSMS. You just need your name, email address, phone number and a password.
                        </div>
                    </div>
                    <div class="step-item">
                        <div class="counter"><span>2</span></div>
                        <div class="details">
                            Add a vessel, then pay just A$249 for 12 months of full access to the app and web dashboard, or trial it free for 7 days.
                        </div>
                    </div>
                    <div class="step-item">
                        <div class="counter"><span>3</span></div>
                        <div class="details">
                            Use our content helpers to quickly add content to your vessel that is relevant to your boat and operation. This is quick
                            and very easy.
                        </div>
                    </div>
                    <div class="step-item">
                        <div class="counter"><span>4</span></div>
                        <div class="details">
                            When you're happy, print out a live snapshot of your new SMS, then download it as a PDF and email it to anyone you like.
                            Easy.
                        </div>
                    </div>
                </div>
                <div class="buttons">
                    <button class="button is-primary" @click="">Get Started</button>
                    <button class="button blue" @click="">Get a Quote</button>
                </div>
            </div>
        </div>

        <div class="tab-content-wrapper two" v-if="tab == '2'">
            <div class="tc-image">
                <img class="db-hero-image" src="@/assets/img/general/devices-1.png" alt="Devices on OffshoreSMS" />
            </div>
            <div class="tc-content">
                <h4>Manage all of your vessels in one place!</h4>
                <div class="home-steps-list">
                    <div class="step-item">
                        <div class="counter"><span>1</span></div>
                        <div class="details">
                            Create a Free user profile on OffshoreSMS. You just need your name, email address, phone number and a password.
                        </div>
                    </div>
                    <div class="step-item">
                        <div class="counter"><span>2</span></div>
                        <div class="details">
                            Add a vessel to your list of vessels. Choose from the Free Logs only plan (basic logs, no charge), or pay just $249 for 12
                            months of access to the app and web dashboard. You can activate a free trial for 7 days too!
                        </div>
                    </div>
                    <div class="step-item">
                        <div class="counter"><span>3</span></div>
                        <div class="details">
                            Use our content helpers to quickly add content to your vessel that is relevant to your boat and your operation. This is
                            quick and very easy.
                        </div>
                    </div>
                    <div class="step-item">
                        <div class="counter"><span>4</span></div>
                        <div class="details">
                            When you're happy, print out a live snapshot of your new SMS, then download it as a PDF and email it to anyone you like.
                            You can also send a link to your SMS for them to view it online in their own time!
                        </div>
                    </div>
                </div>
                <div class="buttons">
                    <button class="button is-primary" @click="">Get Started</button>
                    <button class="button blue" @click="">Get a Quote</button>
                </div>
            </div>
        </div>
    </div>
    <vue-element-loading :active="isLoading" spinner="bar-fade-scale" />
</template>

<script>
import TheWelcome from '@/components/TheWelcome.vue';

import MobileNav from '@/reusables/MobileNav.vue';
import { currentDataStore } from '@/stores/currentdata';
import { getFirestore, collection, doc, arrayUnion, serverTimestamp, addDoc, setDoc, updateDoc } from 'firebase/firestore';

export default {
    setup() {
        const current_data = currentDataStore();
        const db = getFirestore();
        return {
            current_data: current_data,
            db: db
        };
    },
    components: {
        TheWelcome,
        MobileNav
        // VueElementLoading
    },
    mounted() {
        // onAuthStateChanged(getAuth(), (user) => {
        //   if (user) {
        //       this.isLoggedIn = true;
        //   } else {
        //       this.isLoggedIn = false;
        //   }
        // })
    },

    data() {
        return {
            // isLoggedIn: false,
            tab: 1,
            isLoading: false
        };
    },

    methods: {
        setTab(tab) {
            this.current_data.setCurrentVesselTab(tab);
        }

        // showLoader() {
        //   this.isLoading = true;
        //   let self = this;
        //   setTimeout(() => {
        //     self.isLoading = false;
        //   }, 2000);
        // },

        // hideLoader() {
        //   this.isLoading = false;
        // },
    }
};
</script>

<style lang="scss" scoped>
.buttons .button.float {
    z-index: 9999999999999;
}
.page-wrapper {
    margin: 85px 2rem;
}
.header-image {
    background: url('@/assets/img/bgs/homepage1.jpg') no-repeat center center;
    background-size: cover;
    height: 500px;
    display: flex;
    align-items: center;
    max-width: 1400px;
    margin: 0 auto 3rem;
    .hero-content {
        width: 90%;
        margin: 1rem 2rem;
        max-width: 700px;
        h1 {
            font-family: 'Montserrat', sans-serif;
            color: #fff;
            font-weight: bold;
        }
        p {
            color: #fff;
            font-size: 1.2rem;
            margin-bottom: 1rem;
        }
        .button {
            display: inline-block;
        }
    }
}

.tab-wrapper {
    display: flex;
    justify-content: center;
    .round-tab {
        display: flex;
        min-width: 350px;
        min-height: 100px;
        border-radius: 10px;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        background: #f6f6f6;
        flex-direction: column;
        padding: 2rem;
        margin: 0 1rem;
        cursor: pointer;
        span {
            &.icon {
                margin-right: 0;
                font-size: 3rem;
                display: block;
                height: auto;
                width: auto;
            }
            &.text {
                font-size: 1.25rem;
            }
        }
        &.selected {
            background: #00d1b2;
            color: #fff;
        }
    }
}

.tab-content-wrapper {
    background: #f6f6f6;
    display: flex;
    position: relative;
    padding: 4rem;
    max-width: 1200px;
    margin: 4rem auto;
    border-radius: 12px;
    &:before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        top: -40px;
        left: 31%;
        border-style: solid;
        border-width: 0 40px 40px 40px;
        border-color: transparent transparent #f6f6f6 transparent;
    }
    &.two {
        &:before {
            right: 31%;
            left: auto;
        }
    }
    .tc-image {
        width: 50%;
        img {
            max-width: 500px;
            width: 90%;
        }
    }
    .tc-content {
        width: 50%;
        h4 {
            font-family: 'Montserrat', sans-serif;
            font-weight: bold;
            margin-bottom: 1.5rem;
        }
        .home-steps-list {
            margin-bottom: 2.5rem;
            .step-item {
                display: flex;
                margin-bottom: 20px;
                .counter {
                    background-color: #7d2ae8;
                    border-radius: 50%;
                    width: 27px;
                    height: 27px;
                    display: flex;
                    justify-content: center;
                    color: #fff;
                    align-items: center;
                    /* font-weight: bold; */
                    font-size: 0.9rem;
                    margin-right: 8px;
                    margin-top: 3px;
                    span {
                        width: 30px;
                        text-align: center;
                    }
                }
                .details {
                    line-height: 1.7rem;
                    font-size: 1.05rem;
                }
            }
        }
    }
}
</style>
