<template>
    <div class="tab-content-wrapper" :class="tabClass">
        <slot name="heading"></slot>
        <slot name="tab"></slot>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        isFirst: Boolean,
        isLast: Boolean,
        tabClass: String
    },

    mounted() {}
};
</script>

<style scoped lang="scss">
// @media (max-width: 959px) {
//     .tab-content-wrapper {
//         &:before {
//             top: -18px;
//             border-style: solid;
//             border-width: 0 18px 18px 18px;
//         }
//         &.one {
//             &:before {
//                 right: 65px;
//                 left: auto;
//             }
//         }
//         &.two {
//             &:before {
//                 right: 232px;
//                 left: auto;
//             }
//         }
//         &.three {
//             &:before {
//                 right: 360px;
//                 left: auto;
//             }
//         }
//     }
// }
// @media (max-width: 550px) {
//     .tab-content-wrapper {
//         padding: 2rem 1.3rem;
//         &:before {
//             top: -18px;
//             border-style: solid;
//             border-width: 0 18px 18px 18px;
//         }
//         &.one {
//             &:before {
//                 right: 47%;
//                 left: auto;
//             }
//         }
//         &.two {
//             &:before {
//                 right: 47%;
//                 left: auto;
//             }
//         }
//         &.three {
//             &:before {
//                 right: 47%;
//                 left: auto;
//             }
//         }
//     }
// }
</style>
