<template>
    <div class="mobile-nav">
        <div class="menu-logo">
            <RouterLink to="/"><img class="logo" src="@/assets/img/offshoresms-new-logo.png" alt="Offshore SMS" /></RouterLink>
        </div>

        <!-- <div class="field">
          <input id="switchExample" type="checkbox" name="switchExample" class="switch is-rounded" :checked="colorOn" @change="toggleColor">
          <label for="switchExample">Simple Colours</label>
        </div> -->

        <ul class="top-nav-menu">
            <!-- <li v-if="currentVesselBasicData && currentVesselBasicData.vesselID" class="menu-icon clickable" @click="toVesselPage()">
                <div class="round-button-letters vessel pad">
                    <span>{{ makeLetters(currentVesselBasicData.vesselName) }}</span>
                </div>
            </li>
            <li v-if="currentFleet.id" class="menu-icon clickable" @click="toFleetDashboard()">
                <div class="round-button-letters pad">
                    <span>{{ makeLetters(currentFleet.companyName) }}</span>
                </div>
            </li> -->
            <li v-if="!isFreeStanding || userIsLoggedIn">
                <RouterLink to="/my-profile">
                    <span class="menu-icon profile" :class="{ current: currentRoute == 'MyProfile' }"><font-awesome-icon icon="user-cog" /> </span>
                </RouterLink>
            </li>
            <li v-if="!isFreeStanding || userIsLoggedIn">
                <RouterLink to="/">
                    <span class="menu-icon dashboard" :class="{ current: currentRoute == 'MyHomePage' }">
                        <font-awesome-icon class="free" icon="home" />
                    </span>
                </RouterLink>
            </li>
            <!-- <li v-if="!isFreeStanding || userIsLoggedIn">
                <span @click="" class="menu-icon menu" :class="{ current: currentRoute == 'MyHomePage' }">
                    <font-awesome-icon class="free" icon="bars" />
                </span>
            </li> -->
            <li>
                <!-- <div class="trigger-wrapper">
                    <div class="trigger">
                        <svg class="bars" :class="{ active: showNavMenu }" viewBox="0 0 100 100" @click="showNavMenu = !showNavMenu">
                            <path
                                class="line top"
                                d="m 30,33 h 40 c 13.100415,0 14.380204,31.80258 6.899646,33.421777 -24.612039,5.327373 9.016154,-52.337577 -12.75751,-30.563913 l -28.284272,28.284272"></path>
                            <path
                                class="line middle"
                                d="m 70,50 c 0,0 -32.213436,0 -40,0 -7.786564,0 -6.428571,-4.640244 -6.428571,-8.571429 0,-5.895471 6.073743,-11.783399 12.286435,-5.570707 6.212692,6.212692 28.284272,28.284272 28.284272,28.284272"></path>
                            <path
                                class="line bottom"
                                d="m 69.575405,67.073826 h -40 c -13.100415,0 -14.380204,-31.80258 -6.899646,-33.421777 24.612039,-5.327373 -9.016154,52.337577 12.75751,30.563913 l 28.284272,-28.284272"></path>
                        </svg>
                    </div>
                </div> -->
                <Hamburger v-click-outside-menu="() => {}" />
                <!-- <div class="hamburger-wrapper" @click="setShowMobileNav()">
                    <div class="hamburger-icon" id="icon">
                        <div class="icon-1" id="a" :class="{ a: showMobileNav }"></div>
                        <div class="icon-2" id="b" :class="{ c: showMobileNav }"></div>
                        <div class="icon-3" id="c" :class="{ b: showMobileNav }"></div>
                        <div class="clear"></div>
                    </div>
                </div> -->
            </li>
        </ul>
    </div>
    <transition name="slide-fade">
        <div v-if="showMobileNav" class="dropmenu">
            <ul>
                <li>
                    <router-link to="my-profile">
                        <span><font-awesome-icon icon="user-cog" /></span> &nbsp;My Profile
                    </router-link>
                </li>
                <li>
                    <router-link to="/">
                        <span><font-awesome-icon icon="home" /></span> &nbsp;My Home Page
                    </router-link>
                </li>
                <li>
                    <router-link to="/support">
                        <span><font-awesome-icon icon="life-ring" /></span> &nbsp;Help & Support
                    </router-link>
                </li>
                <li @click="toUrl('https://docs.offshoresms.com.au')">
                    <span><font-awesome-icon icon="book-open" /></span> &nbsp;Documentation
                </li>
                <!-- <li>
                    <span><font-awesome-icon icon="info-circle" /></span> &nbsp;Useful Links
                </li> -->
                <li style="text-align: right"><div class="version">Web Version 1.3.29</div></li>
            </ul>
        </div>
    </transition>
</template>

<script>
import { reactive } from 'vue';
import { loggedInUserStore } from '@/stores/userdata';
import { currentDataStore } from '@/stores/currentdata';
import Hamburger from '@/reusables/Hamburger.vue';

export default {
    setup() {
        const user_store = loggedInUserStore();
        const current_data = currentDataStore();
        // Return all consts to access in template
        // return {user: user, anotherVariable: anotherVariable}
        return {
            user_store: user_store,
            current_data: current_data
        };
    },
    components: {
        Hamburger
    },
    computed: {
        showMobileNav() {
            return this.current_data.getShowMobileNav;
        },
        userData() {
            return this.user_store.getUserData;
        },
        userIsLoggedIn() {
            return this.user_store.getUserIsLoggedIn;
        },
        colorOn() {
            return this.current_data.getColorOn;
        },
        currentFleetID() {
            return this.current_data.getCurrentFleetID;
        },
        currentFleet() {
            return this.current_data.getCurrentFleet;
        },
        isFreeStanding() {
            return this.currentRoute == 'SmsSnapshot' && !this.userIsLoggedIn;
        },
        currentVesselBasicData() {
            return this.current_data.getCurrentVesselBasicData;
        }
    },
    mounted() {
        return (this.currentRoute = this.$route.name);
    },
    unmounted() {
        this.showNavMenu = false;
    },
    data() {
        return {
            currentRoute: '',
            showNavMenu: false
        };
    },

    methods: {
        setShowMobileNav() {
            this.current_data.setMobileNav(!this.showMobileNav);
        },

        toggleColor() {
            this.current_data.setColorOn(!this.colorOn);
        },

        toUrl(url) {
            console.log(url);
            window.open(url, '_blank');
        },

        makeLetters(displayName) {
            // console.log(this.currentFleet.id);
            let res = displayName.length > 1 ? displayName.split(' ') : displayName;
            if (res.length > 1) {
                return res[0].substring(0, 1) + res[1].substring(0, 1);
            } else {
                return res[0].substring(0, 1);
            }
        },
        toVesselPage() {
            this.current_data.setCurrentVesselTab('1');
            this.current_data.setCurrentVessel(this.currentVesselBasicData.vesselID);
            this.current_data.setCurrentVesselCrews(this.currentVesselBasicData.vesselID);
            this.current_data.setSnapshotSections([]);
            this.$router.push({
                path: '/manage-asset'
            });
        },

        toFleetDashboard() {
            if (this.isFreeStanding) return;
            // this.current_data.setSnapshotSections([]);
            this.$router.push({
                path: '/fleet-dashboard'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.mobile-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    place-items: center;
    margin-right: 1rem;
    background: #fff;
    z-index: 3002;
    min-height: 52px;
    padding-left: 95px;
    .menu-logo {
        width: 136px;
    }
    .top-nav-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
            display: inline-block;
            a {
                padding: 8px 5px;
                display: block;
            }
            .version {
                color: grey;
            }
        }
    }
    .menu-icon {
        color: #9a9a9a;
        font-size: 22px;
        margin: 7px;
        transition: 0.25s;
        cursor: pointer;
        &.profile {
            &.current,
            &:hover {
                color: orange;
            }
        }
        &.dashboard {
            &.current,
            &:hover {
                color: mediumseagreen;
            }
        }
        &.menu {
            margin-left: 12px;
            font-size: 1.8rem;
            &.current,
            &:hover {
                color: steelblue;
            }
        }
    }
}
input[type='checkbox']:focus {
    outline: none;
}
.switch[type='checkbox']:focus + label::after,
.switch[type='checkbox']:focus + label::before,
.switch[type='checkbox']:focus + label:after,
.switch[type='checkbox']:focus + label:before {
    outline: none !important;
}

// .trigger-wrapper {
//     position: relative;
//     display: inline-block;
//     width: 40px;
//     height: 40px;
//     margin: 0 5px;
//     cursor: pointer;

//     .trigger {
//         position: absolute;
//         z-index: 2;
//         left: 50%;
//         top: 50%;
//         border: 2px solid #f00;
//         border-radius: 10px;
//         transform: translateX(-50%) translateY(-50%);
//     }
//     .bars {
//         width: 80px;
//         cursor: pointer;
//         .line {
//             fill: none;
//             stroke: #f00;
//             stroke-width: 4;
//             stroke-linecap: square;
//             transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
//         }
//         .line.top {
//             stroke-dasharray: 40 172;
//         }
//         .line.middle {
//             stroke-dasharray: 40 111;
//         }
//         .line.bottom {
//             stroke-dasharray: 40 172;
//         }
//     }
//     .bars.active {
//         .top {
//             stroke-dashoffset: -132px;
//         }
//         .middle {
//             stroke-dashoffset: -71px;
//         }
//         .bottom {
//             stroke-dashoffset: -132px;
//         }
//     }
// }

.icon-1,
.icon-2,
.icon-3 {
    position: absolute;
    left: 23%;
    top: 48%;
    width: 22px;
    height: 2px;
    background-color: #fff;
    transition: all 400ms cubic-bezier(0.84, 0.06, 0.52, 1.8);
}

.icon-1 {
    transform: translateY(-8px);
    animation-delay: 100ms;
}

.icon-3 {
    transform: translateY(8px);
    animation-delay: 250ms;
}

.hamburger-wrapper {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 42px;
    margin: 0 7px;
    cursor: pointer;
    .hamburger-icon {
        position: absolute;
        height: 42px;
        width: 42px;
        top: 10%;
        left: 3%;
        z-index: 1000;
        cursor: pointer;
        border-radius: 50%;
        transition: all 0.2s ease-in-out;
        background: steelblue;
        // background: green;
        // width: auto;
        // height: 50px;
        // overflow: hidden;
        &:hover {
            // .icon-1 {
            //   transform: rotate(40deg);
            // }
            // .icon-3 {
            //   transform: rotate(-40deg);
            // }
            // .icon-2 {
            //   opacity: 0;
            // }
            transform: scale(1.1);
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        }
    }

    .icon-1.a {
        transform: rotate(40deg);
    }
    .icon-3.b {
        transform: rotate(-40deg);
    }
    .icon-2.c {
        opacity: 0;
    }
}

@keyframes slideIn {
    0% {
        width: 0%;
        // transform: scale(.3);
        opacity: 0;
    }

    100% {
        width: 50%;
        // transform:scale(1);
        opacity: 1;
    }
}

.dropmenu {
    position: fixed;
    border-radius: 8px;
    padding: 2rem;
    background: steelblue;
    z-index: 99999;
    top: 65px;
    right: 12px;
    min-width: 280px;
    ul {
        li {
            color: #fff;
            border-bottom: 1px dashed #77a6cd;
            padding: 15px 0;
            cursor: pointer;
            transition: 0.25s;
            span {
                transition: 0.25s;
                display: inline-block;
            }
            &:hover {
                span {
                    transform: scale(1.2);
                    color: aqua;
                }
            }
            a {
                color: #fff;
                text-decoration: none;
            }
        }
    }
}

.round-button-letters {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    // text-align: center;
    position: relative;
    background: orange;
    display: flex;
    // margin: 10px 10px 25px;
    margin: 0;
    float: left;
    transition: 0.2s;
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.vessel {
        background: steelblue;
    }
    &:hover {
        background: cadetblue;
    }
    &.pad {
        margin: 0 5px;
    }
    span {
        // display: block;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%,-50%);
        // line-height: 1rem;
        font-size: 14px;
        color: #fff;
        font-weight: 400;
        display: block;
        padding-top: 0;
    }
}
</style>
