<template>
    <div class="modal" v-bind:class="{ 'is-active': showModal }">
        <div class="modal-background" @click="close"></div>
        <div class="modal-content fullwidth">
            <header class="modal-card-head is-dark" :class="theme">
                <!-- <p class="modal-card-title">{{ title }}</p> -->
                <!-- <button class="delete" aria-label="hasSeenNotice" @click="close()"></button> -->
                <button class="delete" aria-label="close" @click.prevent="close"></button>
            </header>
            <div class="box">
                <div class="slot-content">
                    <h4 class="centered">{{ title }}</h4>
                    <!-- <slot>Loading content...</slot> -->
                    <div class="bg-choice-wrapper">
                        <div
                            v-for="(img, idx) in backgrounds"
                            :key="`bg-${idx}`"
                            :style="{ backgroundImage: `url(${img.url})` }"
                            class="bg-option"
                            @click="toggleImage(idx, img.title)"
                            :class="{ selected: currentBG == img.title }">
                            <div v-if="currentBG == img.title" class="check"><font-awesome-icon icon="check" /></div>
                        </div>
                    </div>
                </div>
                <div class="buttons">
                    <button class="button is-delete" @click="close()">Cancel</button>
                    <button :disabled="currentBG == 'default'" class="button is-primary" @click="save()">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imgHelm from '@/assets/img/bgs/dashboard1.jpg';
import imgHarbour from '@/assets/img/bgs/harbour1.jpg';
import imgYacht from '@/assets/img/bgs/yacht1.jpg';
import imgCorals from '@/assets/img/bgs/corals.jpg';
import imgPalms from '@/assets/img/bgs/palms.jpg';
import imgSunset from '@/assets/img/bgs/sunset-ship.jpg';
import imgFishing from '@/assets/img/bgs/fishing-boat.jpg';
import imgRopes from '@/assets/img/bgs/ropes.jpg';
import imgShip from '@/assets/img/bgs/harbour2.jpg';

export default {
    props: {
        title: String,
        theme: String,
        submitText: String,
        selectedBG: String
    },
    mounted() {
        this.currentBG = this.selectedBG ?? 'default';
    },
    computed: {
        selectedBackground() {
            return this.backgrounds.filter((m) => m.selected).map((n) => n.title)[0];
        }
    },
    methods: {
        close() {
            this.showModal = false;
            let result = {
                answer: 'Modal closed',
                modal: false
            };
            this.$emit('closeModal', result);
        },
        save() {
            console.log(this.currentBG);
            this.showModal = false;
            let result = {
                answer: this.currentBG,
                modal: false
            };
            this.$emit('saveBackground', result);
        },
        toggleImage(idx, title) {
            // this.backgrounds.forEach((n) => (n.selected = false));
            // this.backgrounds[idx].selected = true;
            this.currentBG = title;
        }
    },
    data() {
        return {
            showModal: true,
            currentBG: 'default',
            // backgrounds: [
            //     { title: 'helm', url: require('@/assets/img/bgs/dashboard1.jpg'), selected: false },
            //     { title: 'harbour', url: require('@/assets/img/bgs/harbour1.jpg'), selected: false },
            //     { title: 'yacht', url: require('@/assets/img/bgs/yacht1.jpg'), selected: false },
            //     { title: 'corals', url: require('@/assets/img/bgs/corals.jpg'), selected: false },
            //     { title: 'palms', url: require('@/assets/img/bgs/palms.jpg'), selected: false },
            //     { title: 'sunset', url: require('@/assets/img/bgs/sunset-ship.jpg'), selected: false },
            //     { title: 'fishing', url: require('@/assets/img/bgs/fishing-boat.jpg'), selected: false },
            //     { title: 'ropes', url: require('@/assets/img/bgs/ropes.jpg'), selected: false },
            //     { title: 'ship', url: require('@/assets/img/bgs/harbour2.jpg'), selected: false }
            // ],
            backgrounds: [
                { title: 'helm', url: imgHelm, selected: false },
                { title: 'harbour', url: imgHarbour, selected: false },
                { title: 'yacht', url: imgYacht, selected: false },
                { title: 'corals', url: imgCorals, selected: false },
                { title: 'palms', url: imgPalms, selected: false },
                { title: 'sunset', url: imgSunset, selected: false },
                { title: 'fishing', url: imgFishing, selected: false },
                { title: 'ropes', url: imgRopes, selected: false },
                { title: 'ship', url: imgShip, selected: false }
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
.slot-content {
    margin-bottom: 1rem;
}
.bg-choice-wrapper {
    display: grid;
    max-width: 1800px;
    // margin: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
    // padding: 0 2rem;
    .bg-option {
        height: 95px;
        background-position: center center;
        background-size: cover;
        margin: 10px;
        border-radius: 5px;
        cursor: pointer;
        opacity: 0.5;
        transition: 0.25s;
        &.selected {
            opacity: 1;
        }
        &:hover {
            opacity: 1;
        }
        .check {
            position: absolute;
            top: -6px;
            right: -6px;
            color: #fff;
            background: mediumseagreen;
            border-radius: 50%;
            padding: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
        }
    }
}

@media (max-width: 900px) {
    .bg-choice-wrapper {
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 479px) {
    .bg-choice-wrapper {
        grid-template-columns: 1fr;
    }
}
</style>
