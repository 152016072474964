<template>
    <div class="modal" v-bind:class="{ 'is-active': showModal }">
        <div class="modal-background" @click="close()"></div>
        <div class="modal-content">
            <div class="box">
                <vue-element-loading :active="showLoading" spinner="bar-fade-scale" />
                <h3>{{ title }}</h3>
                <slot></slot>
                <div class="spacer s20"></div>
                <div class="buttons centered">
                    <button v-if="!dontShowCancel" class="button is-light" @click="close()">{{ cancelText ?? 'Cancel' }}</button>
                    <button class="button" :class="theme" @click="confirm()">{{ submitText ? submitText : 'Done' }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        theme: String,
        title: String,
        submitText: String,
        cancelText: String,
        showLoading: Boolean,
        dontShowCancel: Boolean
    },
    mounted() {
        setTimeout(() => {
            this.mounted = true;
        }, 500);
    },
    computed: {},
    methods: {
        close() {
            this.showModal = false;
            let result = {
                response: false
            };
            this.$emit('cancel', result);
        },

        confirm() {
            //this.showModal = false;
            let result = {
                response: true
            };
            this.$emit('sendResult', result);
        }
    },
    data() {
        return {
            showModal: true,
            mounted: false
        };
    }
};
</script>

<style lang="scss" scoped>
h3 {
    font-size: 1.3rem;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 1px;
}
.buttons {
    margin-bottom: 12px;
}
.modal-content,
.modal-card {
    width: 95%;
    max-width: 1400px;
    max-height: 80vh;
    border-radius: 6px;
    &:not(.fullwidth) {
        max-width: 440px;
    }
}

@media (min-width: 1024px) {
}
</style>
