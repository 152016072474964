<template>
    <div class="item">
        <div class="details">
            <slot></slot>

            <div class="company-link" :class="[{ deleting: showDelete }, { isSupplier: isSupplierFleet }]">
                <vue-element-loading :active="isLoading" spinner="bar-fade-scale" />
                <div class="fleet-content-wrapper" @click="toFleetDashboardNew()">
                    <div class="avatar" v-if="sentFleet.avatarUrl && sentFleet.avatarUrl != ''">
                        <img :src="sentFleet.avatarUrl" :alt="sentFleet.companyName" />
                    </div>
                    <div v-else class="round-button-letters">
                        <span>{{ makeLetters(sentFleet.companyName) }}</span>
                    </div>
                    <div class="content-box">
                        <h4>{{ sentFleet.companyName }}</h4>
                        <span>{{ sentFleet.companyState ?? 'Not set' }}</span>
                    </div>
                    <!-- <span v-if="isNumzaan" class="quick-remove"></span> -->
                </div>
                <div
                    class="fave-fleet-wrapper"
                    :class="{ faved: myFaveFleetIDs.includes(sentFleet.companyID) }"
                    @click="saveFaveFleet(sentFleet.companyID)">
                    <div class="fave-fleet"><font-awesome-icon icon="star" /></div>
                </div>

                <div class="clear"></div>
            </div>
        </div>
    </div>

    <ConfirmDialog
        v-if="confirmDelete"
        title="Are you sure?"
        theme="is-danger"
        submitText="Yes, Remove me"
        @cancel="confirmDelete = false"
        @sendResult="deleteFleet($event)">
        <div class="centered">
            <h5 class="red">Remove me from {{ sentFleet.companyName }}</h5>
            <p>This cannot be undone.</p>
        </div>
    </ConfirmDialog>
</template>

<script>
import { currentDataStore } from '@/stores/currentdata';
import { loggedInUserStore } from '@/stores/userdata';
import { getFirestore, updateDoc, doc, arrayUnion, arrayRemove, query, getDocs, collection, deleteDoc } from 'firebase/firestore';
import ConfirmDialog from '@/components/modals/ConfirmDialog.vue';

import { getFunctions, httpsCallable } from 'firebase/functions';
// const functions = getFunctions();
//

export default {
    setup() {
        const user_store = loggedInUserStore();
        const db = getFirestore();
        return {
            user_store: user_store,
            current_data: currentDataStore(),
            db: db
        };
    },
    props: {
        sentFleet: Object,
        showDelete: Boolean,
        isSupplierFleet: Boolean
    },
    computed: {
        myFaveFleetIDs() {
            return this.user_store.getMyFaveFleetIDs;
        },
        userData() {
            return this.user_store.getUserData;
        }
    },
    mounted() {
        this.current_data.clearFleet();
    },
    components: {
        ConfirmDialog
    },
    data() {
        return {
            isLoading: false,
            loadAssetData: true,
            confirmDelete: false,
            fleetAssetIDs: []
        };
    },
    methods: {
        async deleteFleet() {
            this.confirmDelete = false;
            this.isLoading = true;
            const q = query(collection(this.db, '/companies/' + this.sentFleet.companyID + '/companyVessels'));
            const snap = await getDocs(q);
            this.fleetAssetIDs = snap.docs.map((doc) => doc.id);
            let stop = this.userData.vesselsThatICrew.some((ai) => this.fleetAssetIDs.includes(ai));
            if (stop) {
                this.isLoading = false;
                alert('You are still connected to one or more assets in this fleet.');
                return;
            } else {
                this.removeFromFleet();
            }
        },

        async removeFromFleet() {
            let userID = this.userData.userid;
            let fleetID = this.sentFleet.companyID;

            // remove me from this fleet's team members
            await deleteDoc(doc(this.db, 'companies/' + fleetID + '/teamMembers', userID));

            // remove this fleet from myFleets
            await deleteDoc(doc(this.db, 'userdata/' + userID + '/myFleets', fleetID));

            // remove this fleet from myCompanies
            await deleteDoc(doc(this.db, 'userdata/' + userID + '/myCompanies', fleetID));

            // remove any adminNotifications for this user and fleet
            await deleteDoc(doc(this.db, 'companies/' + fleetID + '/adminNotifications', userID));

            // remove any adminNotifications for this user and fleet
            await deleteDoc(doc(this.db, 'companies/' + fleetID + '/companyCrew', userID));

            const removeUserFromAllAssets = httpsCallable(getFunctions(), 'removeUserFromAllAssets');
            removeUserFromAllAssets({
                userID: userID,
                vesselIDs: this.fleetAssetIDs,
                fleetID: fleetID
            });

            await updateDoc(doc(this.db, 'companies/', fleetID), {
                adminUsers: arrayRemove(userID),
                adminEmails: arrayRemove(this.userData.useremail ?? ''),
                primaryAdminIDs: arrayRemove(userID),
                adminRestrictedUsers: arrayRemove(userID)
            });

            this.isLoading = false;

            // =======
            // db.collection('companies')
            //     .doc(self.currentCompany.id)
            //     .collection('teamMembers')
            //     .doc(self.crewID)
            //     .delete()
            //     .then((_) => {
            //         db.collection('userdata').doc(self.crewID).collection('myFleets').doc(self.currentCompany.id).delete();
            //         db.collection('userdata').doc(self.crewID).collection('myCompanies').doc(self.currentCompany.id).delete();
            //         db.collection('companies').doc(self.currentCompany.id).collection('adminNotifications').doc(self.crewID).delete();
            //     })
            //     .then((_) => {
            //         var addFleetRevision = firebase.functions().httpsCallable('addFleetRevision');
            //         addFleetRevision({
            //             userName: self.userData.username,
            //             userID: self.userData.userid,
            //             fleetName: self.currentCompany.name,
            //             fleetID: self.currentCompany.id,
            //             revisionDetails:
            //                 self.userData.username +
            //                 ' has removed ' +
            //                 self.crewDetails.username +
            //                 ' as a team member in the fleet ' +
            //                 self.currentCompany.name
            //         });
            //         let removeUserFromAllAssets = firebase.functions().httpsCallable('removeUserFromAllAssets');
            //         removeUserFromAllAssets({
            //             userID: self.crewID,
            //             vesselIDs: self.currentFleetVesselIDs,
            //             fleetID: self.currentCompany.id
            //         });
            //         db.collection('companies')
            //             .doc(self.currentCompany.id)
            //             .update({
            //                 adminUsers: firebase.firestore.FieldValue.arrayRemove(self.crewID),
            //                 adminEmails: firebase.firestore.FieldValue.arrayRemove(self.crewDetails.useremail),
            //                 adminRestrictedUsers: firebase.firestore.FieldValue.arrayRemove(self.crewID)
            //             });
            //     });
        },

        async toFleetDashboardNew() {
            if (this.showDelete) {
                this.confirmDelete = true;
            } else {
                this.isLoading = true;
                let userType = this.isSupplierFleet ? 'supplier' : 'admin';
                let pathName = this.isSupplierFleet ? 'SupplierDashboard' : 'FleetDashboard';
                // let pathName = this.isSupplierFleet ? 'SupplierDashboard' : 'FleetDashAllWidgets';
                this.current_data.setCurrentFleet(this.sentFleet.companyID, this.isSupplierFleet);
                this.current_data.setUserType(userType);
                this.$router.push({
                    name: pathName,
                    params: {}
                });
            }
        },

        async toFleet() {
            // this.current_data.loadAllAssetData(this.currentFleetID);
            // this.current_data.setCurrentFleet(this.currentFleetID);
            // this.current_data.SETCURRENTASSETGROUPS(this.currentFleetID);
            // this.current_data.SETCURRENTADMINPERMISSIONS(this.currentFleetID);

            if (this.showDelete) {
                this.confirmDelete = true;
            } else {
                if (this.isSupplierFleet) {
                    let self = this;
                    this.isLoading = true;
                    setTimeout(() => {
                        this.current_data.setCurrentFleet(this.sentFleet.companyID);
                        this.current_data.loadFleetSuppliers();
                        this.current_data.setUserType('supplier');
                        // this.current_data.setFleetItemTags();
                        // this.current_data.setFleetTeamTags();
                        // this.current_data.setFleetAssetTags();
                        // this.current_data.SETFLEETHISTORYSNAPSHOT();
                        // this.current_data.SETCURRENTASSETGROUPS(this.sentFleet.companyID);
                        // this.current_data.SETCURRENTADMINPERMISSIONS(this.sentFleet.companyID);
                        this.$router.push({
                            name: 'SupplierDashboard',
                            params: {
                                loadAssetData: 'yes'
                            }
                        });
                    }, 500);
                } else {
                    let self = this;
                    this.isLoading = true;
                    setTimeout(() => {
                        // this.current_data.setCurrentFleet(this.sentFleet.companyID); // was here..
                        this.current_data.setFleetItemTags();
                        this.current_data.setFleetTeamTags();
                        this.current_data.setFleetAssetTags();
                        this.current_data.setUserType('admin');
                        this.current_data.SETFLEETHISTORYSNAPSHOT();
                        // this.current_data.SETCURRENTASSETGROUPS(this.sentFleet.companyID);
                        // this.current_data.SETCURRENTADMINPERMISSIONS(this.sentFleet.companyID);

                        this.current_data.DELAYEDSETGROUPASSETS(this.sentFleet.companyID); // <- now includes: this.current_data.setCurrentFleet(this.sentFleet.companyID);
                        this.$router.push({
                            name: 'FleetDashboard',
                            params: {
                                loadAssetData: 'yes'
                            }
                        });
                    }, 500);
                }
            }
        },
        makeLetters(companyName) {
            let res = companyName.split(' ');
            if (res.length > 1) {
                return res[0].substring(0, 1) + res[1].substring(0, 1);
            } else {
                return res[0].substring(0, 1);
            }
        },

        async saveFaveFleet(fleetID) {
            // console.log(fleetID);
            let add = !this.myFaveFleetIDs.includes(fleetID);

            this.isLoading = true;
            const docRef = doc(this.db, 'userdata/' + this.userData.userid);
            await updateDoc(docRef, {
                myFaveFleetIDs: add ? arrayUnion(fleetID) : arrayRemove(fleetID)
            });
            this.isLoading = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.item {
    // margin-top: 2rem;
    display: flex;
    text-align: left;
}

.details {
    flex: 1;
}

h3 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.4rem;
    color: var(--color-heading);
}

.company-link {
    margin-bottom: 1rem;
    cursor: pointer;
    background: steelblue;
    border-radius: 4px;
    transition: 0.3s;
    display: flex;
    // place-items: center;
    padding: 8px 0;
    justify-content: space-between;
    display: flex;
    &.deleting {
        background: red;
    }
    .fleet-content-wrapper {
        width: calc(100% - 50px);
        display: flex;
        .round-button-letters {
            width: 40px;
            height: 40px;
            min-width: 40px;
            border-radius: 50%;
            text-align: center;
            position: relative;
            background: #fff;
            display: block;
            // margin: 10px 10px 25px;
            margin-left: 1rem;
            margin-top: 12px;
            // float: left;
            transition: 0.3s;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            span {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                line-height: 1rem;
                font-size: 15px;
                color: steelblue;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
        .avatar {
            width: 40px;
            height: 40px;
            min-width: 40px;
            margin-left: 1rem;
            margin-top: 12px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .content-box {
            text-align: left;
            padding: 10px;
            // width: calc(100% - 65px);
            h4 {
                text-align: left;
                color: #fff;
                margin-bottom: 0;
                // text-transform: uppercase;
                font-weight: bold;
                font-size: 1rem;
                line-height: 17px;
                padding-top: 4px;
                word-break: break-word;
                font-family: 'Open Sans', sans-serif;
                letter-spacing: 0.5px;
            }
            span {
                font-size: 0.9rem;
                color: #fff;
                word-break: break-word;
            }
        }
    }
    .fave-fleet-wrapper {
        width: 40px;
        height: 40px;
        opacity: 0;
        margin-right: 8px;
        transition: 0.3s;
        .fave-fleet {
            width: 40px;
            height: 40px;
            background-color: transparent;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #d5d5d5;
        }
        &:hover {
            .fave-fleet {
                background-color: white;
                color: mediumseagreen;
            }
        }
        &.faved {
            opacity: 1;
            .fave-fleet {
                color: yellow;
            }
            &:hover {
                .fave-fleet {
                    background-color: white;
                    color: mediumseagreen;
                }
            }
        }
    }
    &:hover {
        background: #5aa6e5;
        .fleet-name {
            .content-box {
                h4 {
                    color: #fff;
                }
                span {
                    color: lightskyblue;
                }
                div {
                    color: lightskyblue;
                }
            }
        }
        .fleet-icon {
            .avatar {
                border: none;
            }
            .round-button-letters {
                background: #fff;
                span {
                    color: #4682b4;
                }
            }
        }
        .fave-fleet-wrapper {
            opacity: 1;
        }
    }
    &.isSupplier {
        background-color: #59bd8a;
        &:hover {
            background-color: #42a974;
        }
    }
}

@media (max-width: 1024px) {
    .item {
        margin-top: 0;
        padding: 0;
    }
}
</style>
