import { defineStore } from 'pinia';
import {
    getFirestore,
    doc,
    getDoc,
    collection,
    query,
    where,
    limit,
    orderBy,
    onSnapshot,
    updateDoc,
    getDocs,
    arrayUnion,
    arrayRemove
} from 'firebase/firestore';

import { loggedInUserStore } from '@/stores/userdata';
// import { CompanyVessels } from '@/types/CompanyVessels.types';
// import { Vessel } from '@/types/Vessel.types';
// import { Fleet, FleetVesselsMap } from '@/types/Fleet.types';
// import { PageData } from '@/types/PageData.types';
// import { Plan } from '@/types/Plan.types';
// import { Crew } from '@/types/Crew.types';
// import { DashboardSettings } from '@/types/DashboardSettings.types';

export const currentDataStore = defineStore({
    id: 'currentData', // must be unique for each store file
    state: () => ({
        loadingStatus: false,
        userID: '',
        isNumzaan: false,
        currentFleetID: {},
        currentFleet: {},
        currentFleetLoaded: false,
        currentFleetUserXCD: false,

        fleetDashboardSetup: false,

        currentVesselID: '',
        currentVesselName: '',
        currentVessel: {},
        currentVesselBasicData: {},
        currentVesselLoaded: false,
        currentBuilderLoaded: true,
        currentVesselIsFleet: false,
        currentVesselPlans: false,
        currentAuditVessels: [],
        currentVesselStats: {},

        currentFleetVessels: [],
        currentFleetVesselsLoaded: false,
        currentFleetVesselsMap: {},
        currentFleetVesselsData: [],
        currentFleetItemTags: [],
        currentFleetAssetTags: [],
        currentFleetTeamTags: [],

        currentTeamMember: {},

        currentAssetGroups: [],
        currentAdminGroupIDs: [],
        currentAdminPermissions: {},

        latestAssetAudit: {},
        systemNotifications: [],
        companyNotices: [],

        colorOn: false,

        currentPageData: {},
        currentVesselTab: '1',
        currentRequiredPlans: [],

        squareCustomerID: '',
        myDashboardSettings: {},
        baseDashboardSettings: {
            vessels: [true, true, true, true, true, true, true, true, true, true, true],
            people: [true, true, true, true, true, true],
            procedures: [true, true, true, true, true, true],
            logs: [true, true, true, true, true, true, true, true, true, true, true, true, true],
            ops: [true, true, true],
            history: [true, true, true, true, true, true, true, true, true, true, true, true, true]
        },
        adminRestrictedUsers: [],
        currentFleetAdmins: [],
        // currentFleetRestrictedAdmins: [],
        currentFleetPrimaryAdmins: [],
        currentFleetStats: {},
        currentFleetAdminData: [],
        currentFleetUserIsRestricted: false,
        currentAdminStatus: 'none',
        menuExpanded: true,
        snapshotSections: [],
        snapshotSectionsChanged: false,
        allVesselCrewIDs: [],
        allVesselCrews: [],
        filterTags: [],
        filterCategories: [],
        microHelperTags: [],
        slidemenuOpen: false,
        showMobileNav: false,

        currentFleetDashWidgets: [],
        currentFleetDashShortcuts: [],
        shortcutsFleetContent: [],
        shortcutsFaveTeam: [],
        shortcutsFaveAssets: [],
        savedHiddenAssetGroups: [],
        currentAssetCalendarLoaded: false,
        currentAssetCalendarEvents: [],
        currentAssetCustomLogsLoaded: false,
        currentAssetCustomLogs: [],
        currentSelectedTeamTags: [],
        currentSelectedTeamQueryType: 'all',

        currentFleetTeamMembers: [],
        currentUserTeamTags: [],
        currentFleetSuppliers: [],
        currentAssetInventory: [],
        currentFleetSuppliersLoaded: false,
        currentAssetInventoryLoaded: false,

        latestAuditRevisions: [],
        restrictedAdminContent: false,
        userType: '',
        currentInvoiceID: '',

        fleetContentLoaded: {
            teamMembers: false,
            fleetAdmins: false,
            fleetAssets: false,
            assetGroups: false,
            adminPermissions: false,
            fleetSuppliers: false,
            auditVessels: false
        }
    }),

    persist: true,

    getters: {
        getLoadingStatus: (state) => state.loadingStatus,
        getCurrentFleetID: (state) => state.currentFleetID,
        getCurrentFleet: (state) => state.currentFleet,
        getCurrentFleetData: (state) => state.currentFleet,
        getCurrentFleetLoaded: (state) => state.currentFleetLoaded,

        getFleetDashboardSetup: (state) => state.fleetDashboardSetup,

        getCurrentFleetUserXCD: (state) => state.currentFleetUserXCD,

        getCurrentVesselID: (state) => state.currentVesselID,
        getCurrentVesselName: (state) => state.currentVesselName,
        getCurrentVessel: (state) => state.currentVessel,
        getCurrentVesselBasicData: (state) => state.currentVesselBasicData,
        getCurrentVesselLoaded: (state) => state.currentVesselLoaded,
        getCurrentBuilderLoaded: (state) => state.currentBuilderLoaded,
        getCurrentVesselIsFleet: (state) => state.currentVesselIsFleet,
        getCurrentVesselPlans: (state) => state.currentVesselPlans,
        getCurrentAuditVessels: (state) => state.currentAuditVessels,
        getCurrentVesselStats: (state) => state.currentVesselStats,
        getCurrentFleetAssetTags: (state) => state.currentFleetAssetTags,
        getCurrentFleetTeamTags: (state) => state.currentFleetTeamTags,

        getCurrentFleetVessels: (state) => state.currentFleetVessels,
        getCurrentFleetVesselsLoaded: (state) => state.currentFleetVesselsLoaded,
        getCurrentFleetVesselsMap: (state) => state.currentFleetVesselsMap,
        getCurrentFleetVesselsData: (state) => state.currentFleetVesselsData,
        getCurrentFleetItemTags: (state) => state.currentFleetItemTags,

        getCurrentTeamMember: (state) => state.currentTeamMember,

        getCurrentInvoiceID: (state) => state.currentInvoiceID,

        getColorOn: (state) => state.colorOn,
        getCurrentPageData: (state) => state.currentPageData,
        getCurrentVesselTab: (state) => state.currentVesselTab,
        getCurrentRequiredPlans: (state) => state.currentRequiredPlans,

        getSquareCustomerID: (state) => state.squareCustomerID,
        getLatestAssetAudit: (state) => state.latestAssetAudit,
        getSystemNotifications: (state) => state.systemNotifications,
        getCompanyNotices: (state) => state.companyNotices,

        getMyDashboardSettings: (state) => state.myDashboardSettings,
        getAdminRestrictedUsers: (state) => state.adminRestrictedUsers,
        // getCurrentFleetUserIsRestricted: (state) => state.currentFleetUserIsRestricted,
        getCurrentFleetUserIsRestricted: (state) => false, // TEMP FOR DEV
        getCurrentAdminIsPrimary: (state) => state.currentAdminStatus == 'primary',
        getCurrentAdminStatus: (state) => state.currentAdminStatus,
        getCurrentFleetAdmins: (state) => state.currentFleetAdmins,
        getCurrentFleetStats: (state) => state.currentFleetStats,
        getCurrentFleetAdminData: (state) => state.currentFleetAdminData,

        getMenuExpanded: (state) => state.menuExpanded,
        getSnapshotSections: (state) => state.snapshotSections,
        getSnapshotLayoutChanged: (state) => state.snapshotSectionsChanged,

        getAllVesselCrewIDs: (state) => state.allVesselCrewIDs,
        getAllVesselCrews: (state) => state.allVesselCrews,
        getFilterCategories: (state) => state.filterCategories,
        getMicroHelperTags: (state) => state.microHelperTags,
        getFilterTags: (state) => state.filterTags,
        getSlidemenuOpen: (state) => state.slidemenuOpen,
        getShowMobileNav: (state) => state.showMobileNav,

        getCurrentAssetGroups: (state) => state.currentAssetGroups,
        getCurrentAdminPermissions: (state) => state.currentAdminPermissions,
        getCurrentAdminGroupIDs: (state) => state.currentAdminGroupIDs,

        getCurrentFleetDashWidgets: (state) => state.currentFleetDashWidgets,
        getCurrentFleetDashShortcuts: (state) => state.currentFleetDashShortcuts,
        getShortcutsFleetContent: (state) => state.shortcutsFleetContent,
        getShortcutsFaveTeam: (state) => state.shortcutsFaveTeam,
        getShortcutsFaveAssets: (state) => state.shortcutsFaveAssets,
        getSavedHiddenAssetGroups: (state) => state.savedHiddenAssetGroups,

        getCurrentAssetCalendarLoaded: (state) => state.currentAssetCalendarLoaded,
        getCurrentAssetCalendarEvents: (state) => state.currentAssetCalendarEvents,

        getCurrentAssetCustomLogsLoaded: (state) => state.currentAssetCustomLogsLoaded,
        getCurrentAssetCustomLogs: (state) => state.currentAssetCustomLogs,
        getCurrentSelectedTeamTags: (state) => state.currentSelectedTeamTags,
        getCurrentSelectedTeamQueryType: (state) => state.currentSelectedTeamQueryType,

        getCurrentFleetTeamMembers: (state) => state.currentFleetTeamMembers,
        getCurrentUserTeamTags: (state) => state.currentUserTeamTags,

        getFleetSuppliers: (state) => state.currentFleetSuppliers,
        getAssetInventory: (state) => state.currentAssetInventory,

        getLatestAuditRevisions: (state) => state.latestAuditRevisions,
        getRestrictedAdminContent: (state) => state.restrictedAdminContent,
        getUserType: (state) => state.userType,

        getFleetContentLoaded: (state) => state.fleetContentLoaded
    },
    actions: {
        setLoadingStatus(value) {
            this.loadingStatus = value;
        },
        setCurrentBuilderLoaded(value) {
            this.currentBuilderLoaded = value;
        },
        setSlidemenu(value) {
            // console.log(value);
            this.slidemenuOpen = value;
        },
        setMobileNav(value) {
            this.showMobileNav = value;
        },

        setCurrentAssetCalendarLoaded(value) {
            this.currentAssetCalendarLoaded = value;
        },

        setCurrentAssetCalendarEvents(events) {
            this.currentAssetCalendarEvents = events;
        },

        setCurrentAssetCustomLogsLoaded(value) {
            this.currentAssetCustomLogsLoaded = value;
        },

        setCurrentSelectedTeamTags(value) {
            this.currentSelectedTeamTags = value;
        },

        setCurrentSelectedTeamQueryType(value) {
            this.currentSelectedTeamQueryType = value;
        },

        setCurrentAssetCustomLogs(logs) {
            this.currentAssetCustomLogs = logs;
        },

        clearCurrentVesselName() {
            this.currentVesselName = '';
            this.currentVesselID = '';
        },

        async setupFleetDashboard() {
            console.log('userID', this.userID, 'fleetID', this.currentFleetID);
            if (this.userID && this.userID != '') {
                const unsub = onSnapshot(doc(getFirestore(), 'userdata', this.userID, 'myFleets', this.currentFleetID), (doc) => {
                    if (doc.exists()) {
                        this.currentFleetDashWidgets = doc.data().savedDashWidgets ?? [];
                        this.currentFleetDashShortcuts = doc.data().savedDashShortcuts ?? [];
                        this.shortcutsFleetContent = doc.data().shortcutsFleetContent ?? [];
                        this.shortcutsFaveTeam = doc.data().shortcutsFaveTeam ?? [];
                        this.shortcutsFaveAssets = doc.data().shortcutsFaveAssets ?? [];
                        this.savedHiddenAssetGroups = doc.data().savedHiddenAssetGroups ?? [];
                    }
                });
            }
            // following to be setup either on fleet dashboard or sub landing pages !!
            // this.setFleetItemTags();
            // this.setFleetTeamTags();
            // this.setFleetAssetTags();
            // await this.loadCurrentFleetTeam(fleetID); // load in fleet team dash
            // await this.loadCurrentFleetVessels(this.currentFleetID);
            // await this.loadSystemNotifications(this.currentFleetID);
            // await this.loadCompanyNotices(this.currentFleetID);
            // await this.SETFLEETHISTORYSNAPSHOT(); // needed??
            // this.SETCURRENTASSETGROUPS(this.currentFleetID); // later in the piece?
            // this.SETCURRENTADMINPERMISSIONS(this.currentFleetID); // later in the piece?
            setTimeout(() => {
                this.fleetDashboardSetup = true;
            }, 300);
        },

        clearFleet() {
            this.fleetDashboardSetup = false;
            this.currentFleetLoaded = false;
            this.currentFleetSuppliers = [];
            this.currentFleetSuppliersLoaded = false;
            this.currentAuditVessels = [];
            this.currentAssetGroups = []; // clear new
            this.currentFleetVessels = []; // clear new
            this.currentFleetTeamMembers = []; // clear new
            // new signals
            this.fleetContentLoaded = {
                teamMembers: false,
                fleetAdmins: false,
                fleetAssets: false,
                assetGroups: false,
                adminPermissions: false,
                fleetSuppliers: false,
                auditVessels: false
            };
        },

        setCurrentFleet(fleetID, isSupplier) {
            console.log('setting current fleet', fleetID);

            this.currentFleetLoaded = false;
            this.currentFleetID = fleetID;
            this.currentVesselID = '';
            this.userID = loggedInUserStore().getUserID;
            this.isNumzaan = loggedInUserStore().getIsNumzaan;
            // this.setCurrentFleetDashboard(fleetID);
            onSnapshot(doc(getFirestore(), 'companies', fleetID), (doc) => {
                if (doc.exists()) {
                    const data = doc.data() ?? {};
                    data.id = fleetID;
                    let adminUsers = data.adminUsers ?? [];
                    this.currentFleetAdmins = adminUsers;
                    this.currentFleetUserXCD = adminUsers.includes(this.userID) || this.isNumzaan;
                    let restrictedAdminUsers = data.adminRestrictedUsers ?? [];
                    let primaryAdminIDs = data.primaryAdminIDs ?? [];
                    this.adminRestrictedUsers = data.adminRestrictedUsers ?? [];
                    this.currentAdminStatus = primaryAdminIDs.includes(this.userID)
                        ? 'primary'
                        : adminUsers.includes(this.userID)
                        ? 'full'
                        : restrictedAdminUsers.includes(this.userID)
                        ? 'restricted'
                        : this.isNumzaan
                        ? 'offshore'
                        : 'none';
                    this.currentFleetUserIsRestricted = this.adminRestrictedUsers.includes(this.userID);
                    this.restrictedAdminContent = doc.data().restrictedAdminContent ?? {
                        fleetContent: false,
                        manageRoles: false,
                        customLogs: false,
                        assetInformation: false,
                        assetSettings: false,
                        assetSpecs: false,
                        assetStatements: false
                    };
                    data.assetReloadBin = doc.data().assetReloadBin ?? [];
                    this.currentFleet = data;
                    this.currentFleetStats = data.fleetStats ?? {
                        totalAssets: 0,
                        activeAssets: 0,
                        offlineAssets: 0,
                        totalAssetGroups: 0,
                        totalUngroupedAssets: 0,
                        totalExpiredAssets: 0,
                        assetsExpiringSoon: 0,
                        activeSmsTokens: 0
                    };
                    // console.log('fleetStats', data.fleetStats, this.currentFleetStats);
                    // this.setCurrentFleetAdmins(fleetID, adminUsers); // only used on the fleet assets page to view group admins!!!
                }
            });
            if (!isSupplier) {
                // this.loadCurrentFleetTeam(fleetID); // load in fleet team dash
                // this.loadCurrentFleetVessels(fleetID);
                // this.loadSystemNotifications(fleetID);
                // this.loadCompanyNotices(fleetID);
            } else {
                // load supplier notices??
            }
            setTimeout(() => {
                console.log('finishing up 500');
                this.currentFleetLoaded = true;
            }, 500);
        },

        loadAssetInventory() {
            // Bejai note: Could run into problems later because not publishing whether it is loaded or not
            if (!this.currentAssetInventoryLoaded) {
                const collectionRef = collection(getFirestore(), 'vessels', this.currentVesselID, 'vesselInventory');
                const q = query(collectionRef, where('archived', '==', false), orderBy('index'));
                onSnapshot(q, (querySnapshot) => {
                    this.currentAssetInventory = [];
                    querySnapshot.forEach((doc) => {
                        const data = doc.data();
                        data.id = doc.id;
                        this.currentAssetInventory.push(data);
                    });
                    this.currentAssetInventoryLoaded = true;
                });
            }
        },

        loadFleetSuppliers() {
            // Bejai note: Could run into problems later because not publishing whether it is loaded or not
            if (!this.currentFleetSuppliersLoaded) {
                const collectionRef = collection(getFirestore(), 'companies', this.currentFleetID, 'fleetSuppliers');
                const q = query(collectionRef, where('archived', '==', false), orderBy('index'));
                onSnapshot(q, (querySnapshot) => {
                    this.currentFleetSuppliers = [];
                    querySnapshot.forEach((doc) => {
                        const data = doc.data();
                        data.id = doc.id;
                        this.currentFleetSuppliers.push(data);
                    });
                    this.currentFleetSuppliersLoaded = true;
                });
            }
        },

        async loadCurrentFleetTeam(fleetID) {
            const docRef = doc(getFirestore(), 'companies/' + fleetID + '/teamMembers', this.userID);
            const docSnap = await getDoc(docRef);
            let adminIsFull = false;
            if (docSnap.exists()) {
                let isAdmin = this.currentFleetAdmins.includes(this.userID);
                let isRestrictedAdmin = this.adminRestrictedUsers.includes(this.userID);
                let roles = docSnap.data().roles ?? [];
                let restrictedAdminTag = isRestrictedAdmin ? ['Restricted Admin'] : [];
                let fullAdminTag = isAdmin && !isRestrictedAdmin ? ['Full Admin'] : [];
                adminIsFull = isAdmin && !isRestrictedAdmin;
                this.currentUserTeamTags = [...roles, ...fullAdminTag, ...restrictedAdminTag];
                // this.currentUserTeamTags = [...roles, ...fullAdminTag];
            }
            const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));
            await sleep(1000);

            const q = query(collection(getFirestore(), 'companies/' + fleetID + '/teamMembers'), orderBy('crewName', 'asc'));
            const qualSnapshot = onSnapshot(q, (querySnapshot) => {
                this.currentFleetTeamMembers = [];
                querySnapshot.forEach((doc) => {
                    // console.log('doc', doc.data().crewName);
                    let data = doc.data();
                    data.id = doc.id;
                    let isAdmin = this.currentFleetAdmins.includes(doc.id);
                    data.isAdmin = isAdmin;
                    let isRestrictedAdmin = this.adminRestrictedUsers.includes(doc.id);
                    data.isRestrictedAdmin = isRestrictedAdmin;
                    data.hasAlerts = false;
                    let isPrimaryAdmin = this.currentFleetPrimaryAdmins.includes(doc.id);
                    let roles = doc.data().roles ?? [];
                    let restrictedAdminTag = isRestrictedAdmin ? ['Restricted Admin'] : [];
                    let fullAdminTag = isAdmin && !isRestrictedAdmin ? ['Full Admin'] : [];
                    // data.roles = [...roles, ...fullAdminTag, ...restrictedAdminTag];
                    data.roles = [...roles];
                    data.assetTags = [...roles, ...fullAdminTag, ...restrictedAdminTag];

                    // this.currentFleetTeamMembers.push(data);
                    if (adminIsFull || this.currentUserTeamTags.some((t) => data.roles.includes(t))) this.currentFleetTeamMembers.push(data);
                });
            });
            this.fleetContentLoaded.teamMembers = true;
        },

        async loadCompanyNotices(fleetID) {
            const q = query(
                collection(getFirestore(), 'companies/' + fleetID + '/companyNotices'),
                where('archived', '==', false),
                orderBy('timestamp', 'desc')
            );
            onSnapshot(q, (querySnapshot) => {
                this.companyNotices = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.timestamp = new Date(doc.data().timestamp.toDate());
                    this.companyNotices.push(data);
                });
            });
        },

        async loadSystemNotifications(fleetID) {
            // systemNotifications
            const q = query(
                collection(getFirestore(), 'companies/' + fleetID + '/systemNotifications'),
                where('archived', '==', false),
                orderBy('timestamp', 'desc')
            );
            onSnapshot(q, (querySnapshot) => {
                this.systemNotifications = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.timestamp = new Date(doc.data().timestamp.toDate());
                    this.systemNotifications.push(data);
                });
            });
        },

        // LOADSYSTEMNOTIFICATIONS: (state, payload) => {
        //     // console.log(payload.fleetID);
        //     db.collection('companies')
        //         .doc(payload.fleetID)
        //         .collection('systemNotifications')
        //         .where('archived', '==', false)
        //         .orderBy('timestamp', 'desc')
        //         .onSnapshot((querySnapshot) => {
        //             state.systemNotifications = [];
        //             querySnapshot.forEach(async (doc) => {
        //                 let data = doc.data();
        //                 data.id = doc.id;
        //                 state.systemNotifications.push(data);
        //             });
        //         });
        // },

        setUserType(type) {
            this.userType = type;
        },

        setCurrentFleetAdmins(fleetID, adminUsers) {
            // console.log('XX', fleetID, adminUsers);
            this.currentFleetAdminData = [];
            let adminIDs = [];
            let self = this;
            if (this.adminRestrictedUsers.length > 0) {
                Promise.all(
                    self.adminRestrictedUsers.map(async (id) => {
                        const docRef = await getDoc(doc(getFirestore(), 'userdata', id));
                        if (!adminIDs.includes(id)) {
                            // console.log('adding', docRef.data().username);
                            adminIDs.push(id);
                            self.currentFleetAdminData.push({
                                username: docRef.data().username,
                                userid: id,
                                avatarUrl: docRef.data().avatarUrl ?? '',
                                restricted: true
                            });
                        }
                    })
                );
            }
            if (this.currentFleetAdmins) {
                Promise.all(
                    this.currentFleetAdmins.map(async (id) => {
                        const docRefTwo = await getDoc(doc(getFirestore(), 'userdata', id));
                        if (!adminIDs.includes(id)) {
                            // console.log('adding', docRefTwo.data().username);
                            adminIDs.push(id);
                            self.currentFleetAdminData.push({
                                username: docRefTwo.data().username,
                                userid: id,
                                avatarUrl: docRefTwo.data().avatarUrl ?? '',
                                restricted: false
                            });
                        }
                    })
                );
            }
            console.log('admins set');
            this.fleetContentLoaded.fleetAdmins = true;
        },

        async loadCurrentFleetVessels(fleetID) {
            this.currentFleetVesselsLoaded = false;
            const q = query(collection(getFirestore(), 'companies/' + fleetID + '/companyVessels'), orderBy('vesselName', 'asc'));
            onSnapshot(q, (querySnapshot) => {
                this.currentFleetVessels = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.vesselID = doc.id;
                    this.currentFleetVessels.push(data);
                });
            });
            setTimeout(() => {
                this.currentFleetVesselsLoaded = true;
                // this.loadCurrentFleetVesselsMap(); // ! removed as may not need to load every vessel's data into the state for the fleet dashboard, only audit vessel list!
                this.setCurrentAuditVessels(); // was in loadCurrentFleetVesselsMap()
            }, 500);
            // setTimeout(() => {
            //     this.setCurrentAuditVessels();
            // }, 500);
        },

        async loadAllAssetData(fleetID) {
            // console.log('OK fleet', fleetID, this.currentAdminGroupIDs, this.currentAssetGroups);
            let self = this;
            let temp = this.currentAssetGroups
                .filter((m) => this.currentAdminGroupIDs.includes(m.id))
                .flatMap((g) => g.members)
                .filter((v, i, a) => a.indexOf(v) == i);
            // console.log('temp', temp);
            this.currentFleetVesselsData = [];
            this.currentFleetVesselsMap = {};
            let t = [];
            let fleetStats = {
                totalAssets: 0,
                activeAssets: 0,
                offlineAssets: 0,
                totalAssetGroups: 0,
                totalUngroupedAssets: 0,
                totalExpiredAssets: 0,
                assetsExpiringSoon: 0,
                activeSmsTokens: 0
            };

            const now = new Date();
            const cutOff = new Date(new Date().setDate(new Date().getDate() + 30));
            const dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };

            Promise.all(
                temp.map(async (assetID) => {
                    // console.log('stepped assetID', assetID);
                    const unsub = onSnapshot(doc(getFirestore(), 'vessels', assetID), (doc) => {
                        if (doc.exists()) {
                            const data = doc.data();
                            // console.log('asset', data.vesselName);
                            data.vesselID = assetID;
                            // can force set any other required fields here:
                            // set up stats....
                            let isExpired = doc.data().isExpired ?? false;
                            data.avatarUrl = doc.data().avatarUrl ?? '';
                            // data.nextChargeDate = doc.data().nextChargeDate ? doc.data().nextChargeDate.toDate() : new Date();

                            let xDate = doc.data().nextChargeDate.toDate();
                            data.xDate = xDate;
                            data.isOverdue = xDate < now;
                            data.isDue = xDate < cutOff && xDate > now;
                            data.dueDate = new Date(xDate).toLocaleDateString('en-US', dateOptions);

                            if (!isExpired) {
                                self.currentFleetVesselsMap[assetID] = data;

                                // set up fleet stats
                                fleetStats.totalAssets++;
                                if (data.isActive) fleetStats.activeAssets++;
                                if (data.offlineStatusData?.vesselStatus == 'offline') fleetStats.offlineAssets++;
                                if (data.isExpired) fleetStats.totalExpiredAssets++;
                                if (data.nextChargeDate) {
                                    let nextChargeDate = new Date(data.nextChargeDate);
                                    if (nextChargeDate < cutOff) {
                                        fleetStats.assetsExpiringSoon++;
                                    }
                                }

                                //
                                if (!t.includes(assetID)) {
                                    t.push(assetID);
                                    self.currentFleetVesselsData.push(data);
                                }
                            }
                        }
                    });
                })
            );

            const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));
            await sleep(1000);

            // console.log('fleetStats', fleetStats);
            this.currentFleetStats = fleetStats;
            this.fleetContentLoaded.fleetAssets = true;
            // console.log(
            //     'self.currentFleetVesselsData',
            //     self.currentFleetVesselsData.map((m) => m.vesselName)
            // );

            const updateRef = doc(getFirestore(), 'companies', fleetID);
            await updateDoc(updateRef, {
                assetReloadBin: [],
                fleetStats: fleetStats
            });
        },

        async emptyTheAssetBin(fleetID) {
            let self = this;
            // console.log('Starting to empty the asset bin...');
            const docSnap = await getDoc(doc(getFirestore(), 'companies', fleetID));
            if (docSnap.exists()) {
                const assetReloadBin = docSnap.data().assetReloadBin ?? [];
                if (assetReloadBin.length > 0) {
                    // console.log('assetReloadBin has items - reloading and emptying..');
                    let temp = [...assetReloadBin];
                    const updateRef = doc(getFirestore(), 'companies', fleetID);
                    await updateDoc(updateRef, { assetReloadBin: [] });
                    await Promise.all(
                        temp.map(async (m) => {
                            const unsub = onSnapshot(doc(getFirestore(), 'vessels', m), (doc) => {
                                if (doc.exists()) {
                                    const data = doc.data();
                                    data.vesselID = m;
                                    data.avatarUrl = doc.data().avatarUrl ?? '';
                                    // can force set any other required fields here:
                                    data.isAudited = doc.data().isAudited ?? false;
                                    data.isPrivate = doc.data().isPrivate ?? false;
                                    data.nextChargeDate = doc.data().nextChargeDate;
                                    data.isExpired = doc.data().isExpired ?? false;
                                    data.isActive = doc.data().isActive ?? false;
                                    data.savedAssetTags = doc.data().savedAssetTags ?? [];
                                    self.currentFleetVesselsMap[doc.id] = data;
                                }
                            });
                        })
                    );
                } else {
                    // console.log('assetReloadBin is already empty.');
                }
            }
        },

        async forceReloadFleetAssets(fleetID) {
            let self = this;
            // console.log('R:', this.currentFleetVessels);
            this.currentFleetVesselsMap = {};
            const updateRef = doc(getFirestore(), 'companies', fleetID);
            await updateDoc(updateRef, { assetReloadBin: [] });
            const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));
            await sleep(200);
            await Promise.all(
                this.currentFleetVessels.map(async (m) => {
                    // console.log('m', m.vesselName, m.vesselID);
                    const unsub = onSnapshot(doc(getFirestore(), 'vessels', m.vesselID), (doc) => {
                        if (doc.exists()) {
                            // console.log('m2', m.vesselName, m.vesselID);
                            const data = doc.data();
                            data.vesselID = m.vesselID;
                            data.avatarUrl = doc.data().avatarUrl ?? '';
                            // can force set any other required fields here:
                            data.isAudited = doc.data().isAudited ?? false;
                            data.isPrivate = doc.data().isPrivate ?? false;
                            data.nextChargeDate = doc.data().nextChargeDate;
                            data.isExpired = doc.data().isExpired ?? false;
                            data.isActive = doc.data().isActive ?? false;
                            data.savedAssetTags = doc.data().savedAssetTags ?? [];
                            self.currentFleetVesselsMap[doc.id] = data;
                        }
                    });
                })
            );
        },

        async loadSingleAssetMap(assetID) {
            let self = this;
            const unsub = onSnapshot(doc(getFirestore(), 'vessels', assetID), (doc) => {
                if (doc.exists()) {
                    const data = doc.data();
                    // console.log('reset', data);
                    data.vesselID = assetID;
                    data.avatarUrl = doc.data().avatarUrl ?? '';
                    // can force set any other required fields here:
                    data.isAudited = doc.data().isAudited ?? false;
                    data.isPrivate = doc.data().isPrivate ?? false;
                    data.nextChargeDate = doc.data().nextChargeDate;
                    data.isExpired = doc.data().isExpired ?? false;
                    data.isActive = doc.data().isActive ?? false;
                    data.savedAssetTags = doc.data().savedAssetTags ?? [];
                    self.currentFleetVesselsMap[doc.id] = data;
                }
            });
        },

        // might need this later, otherwise it loads in the function above if navigating from the users' home page to a fleet.
        // async loadCurrentFleetVesselsMap() {
        //     let self = this;
        //     await Promise.all(
        //         self.currentFleetVessels.map(async (vessel) => {
        //             const vesselID = vessel.vesselID ?? '';
        //             const docSnap = await getDoc(doc(getFirestore(), 'vessels', vesselID));
        //             if (docSnap.exists()) {
        //                 const data = docSnap.data();
        //                 data.vesselID = vessel.vesselID;
        //                 data.avatarUrl = docSnap.data().avatarUrl ?? '';
        //                 // return data; // this was the issue - not assigning the vessel data to currentFleetVesselsMap with the key == the vessel's ID
        //                 self.currentFleetVesselsMap[docSnap.id] = data;
        //             }
        //         })
        //     );
        //     setTimeout(() => {
        //         this.setCurrentAuditVessels();
        //     }, 500);
        // },

        async SETCURRENTASSETGROUPS(fleetID) {
            // console.log('delayed SETCURRENTASSETGROUPS');
            let self = this;
            const q = query(collection(getFirestore(), 'companies/' + fleetID + '/assetGroups'), orderBy('index', 'asc'));
            onSnapshot(q, (querySnapshot) => {
                self.currentAssetGroups = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.showRemove = false;
                    data.showRemoveMembers = false;
                    data.showAdmins = false;
                    data.loadingAdmins = false;
                    data.admins = [];
                    self.currentAssetGroups.push(data);
                });
            });
            this.fleetContentLoaded.assetGroups = true;
        },

        async SETCURRENTADMINPERMISSIONS(fleetID) {
            // console.log('delayed SETCURRENTADMINPERMISSIONS');
            const docSnap = await getDoc(doc(getFirestore(), 'companies/' + fleetID + '/adminPermissions', this.userID));
            if (docSnap.exists()) {
                this.currentAdminPermissions = docSnap.data();
                // console.log('WTF', docSnap.data());
                if (docSnap.data().adminAssets) this.currentAdminGroupIDs = docSnap.data().adminAssets;
            }
            this.fleetContentLoaded.adminPermissions = true;
        },

        // async DELAYEDSETGROUPASSETS(fleetID) {
        //     // console.log('delayed DELAYEDSETGROUPASSETS', fleetID);
        //     this.SETCURRENTASSETGROUPS(fleetID);
        //     this.SETCURRENTADMINPERMISSIONS(fleetID);
        //     // const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));
        //     // await sleep(1000);
        //     // this.setCurrentFleet(fleetID);
        // },

        // SETCURRENTADMINGROUPVESSELS: (state, payload) => {
        //     // console.log('XX', payload.fleetID);
        //     state.currentAdminGroupVessels = [];
        //     db.collection('companies')
        //         .doc(payload.fleetID)
        //         .collection('adminPermissions')
        //         .doc(state.user.uid)
        //         .get()
        //         .then((doc) => {
        //             if (doc.exists) {
        //                 let adminAssets = doc.data().adminAssets ? doc.data().adminAssets : [];
        //                 adminAssets.forEach((asset) => {
        //                     db.collection('companies')
        //                         .doc(payload.fleetID)
        //                         .collection('assetGroups')
        //                         .doc(asset)
        //                         .get()
        //                         .then((docRef) => {
        //                             if (docRef.exists) {
        //                                 let members = docRef.data().members ? docRef.data().members : [];
        //                                 state.currentAdminGroupVessels = [...state.currentAdminGroupVessels, ...members];
        //                             }
        //                         });
        //                 });
        //             }
        //         });
        // },

        async SETFLEETHISTORYSNAPSHOT() {
            this.latestAuditRevisions = [];
            const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));
            await sleep(4000);
            // console.log('here');
            let now = new Date();
            let backDate = new Date(now.setDate(now.getDate() - 7));
            // let auditAssets = [...new Set(this.currentAuditVessels.map((m) => m.vesselID))];
            // console.log(
            //     'currentAuditVessels',
            //     this.currentAuditVessels.map((m) => m.vesselID)
            // );

            await Promise.all(
                this.currentAuditVessels.map(async (v) => {
                    const collectionRef = collection(getFirestore(), 'vessels/' + v.vesselID + '/vesselRevisions');
                    const q = query(collectionRef, where('timestamp', '>=', backDate));
                    const querySnapshot = await getDocs(q);
                    let t = {
                        vesselID: v.vesselID,
                        vesselName: v.vesselName,
                        revisions: []
                    };
                    await Promise.all(
                        querySnapshot.docs.map(async (doc, index) => {
                            let revision = doc.data().revision ?? '';
                            if (revision != '') t.revisions.push(revision);
                        })
                    );
                    this.latestAuditRevisions.push(t);
                })
            );
            // console.log(this.latestAuditRevisions);
        },

        setFleetItemTags() {
            const collectionRef = collection(getFirestore(), 'companies/' + this.currentFleetID + '/fleetItemTags');
            const q = query(collectionRef, orderBy('index'));
            onSnapshot(q, (querySnapshot) => {
                this.currentFleetItemTags = [];
                querySnapshot.forEach((doc) => {
                    if (doc.exists()) {
                        const data = doc.data();
                        data.id = doc.id;
                        data.checked = false;
                        this.currentFleetItemTags.push(data);
                    }
                });
            });
        },

        setFleetTeamTags() {
            const collectionRef = collection(getFirestore(), 'companies/' + this.currentFleetID + '/fleetTeamTags');
            const q = query(collectionRef, orderBy('index'));
            onSnapshot(q, (querySnapshot) => {
                this.currentFleetTeamTags = [];
                querySnapshot.forEach((doc) => {
                    if (doc.exists()) {
                        const data = doc.data();
                        data.id = doc.id;
                        data.checked = false;
                        this.currentFleetTeamTags.push(data);
                    }
                });
            });
        },

        setFleetAssetTags() {
            const collectionRef = collection(getFirestore(), 'companies/' + this.currentFleetID + '/fleetAssetTags');
            const q = query(collectionRef, orderBy('index'));
            onSnapshot(q, (querySnapshot) => {
                this.currentFleetAssetTags = [];
                querySnapshot.forEach((doc) => {
                    if (doc.exists()) {
                        const data = doc.data();
                        data.id = doc.id;
                        data.checked = false;
                        this.currentFleetAssetTags.push(data);
                    }
                });
            });
        },

        setCurrentAuditVessels() {
            let self = this;
            let auditVesselIDs = this.currentFleet.auditVesselIDs ?? [];
            if (this.currentFleetVessels.length > 0) {
                this.currentAuditVessels = this.currentFleetVessels.filter((n) => auditVesselIDs.includes(n.vesselID ?? ''));
            }
            // this.SETFLEETHISTORYSNAPSHOT();
        },

        setCurrentVessel(vesselID) {
            this.currentVesselLoaded = false;
            this.currentBuilderLoaded = false;
            this.currentVesselID = vesselID;
            this.currentAssetInventory = [];
            this.currentAssetInventoryLoaded = false;

            const now = new Date();
            const cutOff = new Date(new Date().setDate(new Date().getDate() + 30));
            const dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };
            // get current vessel snapshot and set currentVessel to its snapshot

            onSnapshot(doc(getFirestore(), 'vessels', vesselID), (doc) => {
                if (doc.exists()) {
                    const data = doc.data();

                    let xDate = data.nextChargeDate.toDate();
                    data.xDate = xDate;
                    data.isOverdue = xDate < now;
                    data.isDue = xDate < cutOff && xDate > now;
                    data.dueDate = new Date(xDate).toLocaleDateString('en-US', dateOptions);

                    console.log('loaded vessel', data.vesselName);
                    if (data) {
                        this.currentVessel = data;
                        this.currentVesselName = data.vesselName ?? '';
                        this.currentVesselBasicData = {
                            vesselID: doc.id,
                            vesselName: data.vesselName,
                            avatarUrl: data.avatarUrl ?? ''
                        };
                        this.currentVessel.savedAppInfoSections = data.savedAppInfoSections ?? [];
                        this.currentVessel.vesselIndustries = data.vesselIndustries ?? [];
                        this.currentVessel.customFields = data.customFields ?? [];
                        this.currentVessel.assetType = data.assetType ?? 'vessel';
                        this.currentVessel.assetFilterTags = data.assetFilterTags ?? [];
                        this.currentVesselIsFleet = data.isCompanyVessel ?? false;
                        this.currentVesselPlans = data.aaaPlans ?? [];
                        this.squareCustomerID = data.squareCustomerID ?? '';
                        //
                        this.currentVesselStats = {
                            aaaPlans: data.aaaPlans ?? [],
                            nextChargeDate: data.nextChargeDate ?? new Date(),
                            nextVesselCharge: data.nextVesselCharge ?? 249,
                            isOverdue: xDate < now,
                            isAudited: data.auditVessel ?? false,
                            autoRenew: data.autoRenew ?? false,
                            isActive: data.isActive ?? false,
                            isCompanyVessel: data.isCompanyVessel ?? false,
                            isDemo: data.isDemo ?? false,
                            isFreebie: data.isFreebie ?? false,
                            isExpired: data.isExpired ?? false,
                            offlineStatus: data.offlineStatusData ? data.offlineStatusData.vesselStatus ?? 'live' : 'live',
                            isPrivate: data.isPrivate ?? false,
                            takesPassengers: data.takesPassengers ?? false,
                            savedAssetTags: data.savedAssetTags ?? []
                        };
                    }
                    setTimeout(() => {
                        this.currentVesselLoaded = true;
                        this.currentBuilderLoaded = true;
                    }, 500);
                } else {
                    setTimeout(() => {
                        this.currentVesselLoaded = false;
                    }, 500);
                }
                this.loadMyDashboardSettings();
                this.loadFilterTags();
                this.setLatestAssetAudit(vesselID);
            });
        },

        async setLatestAssetAudit(vesselID) {
            const q = query(collection(getFirestore(), 'vessels/' + vesselID + '/vesselAudits'), limit(1), orderBy('timestamp', 'desc'));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.docs.length > 0) {
                this.latestAssetAudit = querySnapshot.docs[0].data();
            } else {
                this.latestAssetAudit = {};
            }
        },

        async setCurrentTeamMember(crewName, crewID) {
            // console.log('crewName', crewName);
            // const q = query(collection(getFirestore(), 'vessels/' + vesselID + '/vesselAudits'), limit(1), orderBy('timestamp', 'desc'));
            // const querySnapshot = await getDocs(q);
            // if (querySnapshot.docs.length > 0) {
            //     this.latestAssetAudit = querySnapshot.docs[0].data();
            // } else {
            //     this.latestAssetAudit = {};
            // }
            this.currentTeamMember = {
                crewName: crewName,
                crewID: crewID
            };
            // console.log('X:', this.currentTeamMember);
        },

        async setCurrentVesselCrews() {
            setTimeout(async () => {
                let self = this;
                let levelOne = this.currentVessel.listOfCrewIDs ?? [];
                let levelTwo = this.currentVessel.listOfLevelTwoIDs ?? [];
                let levelThree = this.currentVessel.listOfMasterIDs ?? [];
                // console.log(levelOne, levelTwo, levelThree);
                await Promise.all(
                    levelThree.map(async (id) => {
                        if (!self.allVesselCrewIDs.includes(id)) {
                            const docSnap = await getDoc(doc(getFirestore(), 'userdata', id));
                            if (docSnap.exists()) {
                                self.allVesselCrewIDs.push(id);
                                self.allVesselCrews.push({
                                    username: docSnap.data().username,
                                    userid: id,
                                    avatarUrl: docSnap.data().avatarUrl ?? '',
                                    role: 'levelThree'
                                });
                            }
                        }
                    })
                );
                await Promise.all(
                    levelTwo.map(async (id) => {
                        if (!self.allVesselCrewIDs.includes(id)) {
                            const docSnap = await getDoc(doc(getFirestore(), 'userdata', id));
                            if (docSnap.exists()) {
                                self.allVesselCrewIDs.push(id);
                                self.allVesselCrews.push({
                                    username: docSnap.data().username,
                                    userid: id,
                                    avatarUrl: docSnap.data().avatarUrl ?? '',
                                    role: 'levelTwo'
                                });
                            }
                        }
                    })
                );
                await Promise.all(
                    levelOne.map(async (id) => {
                        if (!self.allVesselCrewIDs.includes(id)) {
                            const docSnap = await getDoc(doc(getFirestore(), 'userdata', id));
                            if (docSnap.exists()) {
                                self.allVesselCrewIDs.push(id);
                                self.allVesselCrews.push({
                                    username: docSnap.data().username,
                                    userid: id,
                                    avatarUrl: docSnap.data().avatarUrl ?? '',
                                    role: 'levelOne'
                                });
                            }
                        }
                    })
                );
            }, 2000);
        },

        async updateCurrentVessel(updateData) {
            const updateRef = doc(getFirestore(), 'vessels', this.currentVesselID);
            await updateDoc(updateRef, updateData);
        },

        loadMyDashboardSettings() {
            if (!this.userID) return this.baseDashboardSettings;
            onSnapshot(doc(getFirestore(), 'userdata/' + this.userID + '/dashboardSettings', this.currentVesselID), (doc) => {
                this.myDashboardSettings = doc.data() ?? this.baseDashboardSettings;
            });
        },

        setColorOn(val) {
            this.colorOn = val;
        },

        setCurrentPageData(page) {
            this.currentPageData = page;
        },

        setCurrentVesselTab(tab) {
            this.currentVesselTab = tab;
        },

        setCurrentRequiredPlans(plan) {
            this.currentRequiredPlans = plan;
        },

        setMenuExpanded(val) {
            this.menuExpanded = val;
        },

        setSnapshotSections(val) {
            this.snapshotSections = val;
        },

        setSnapshotLayoutsChanged(val) {
            this.snapshotSectionsChanged = val;
        },

        loadFilterTags() {
            let self = this;
            const q = query(collection(getFirestore(), 'tagLibrary'), orderBy('index', 'asc'));
            onSnapshot(q, (querySnapshot) => {
                this.filterTags = [];
                querySnapshot.forEach((doc) => {
                    // const data = doc.data();  /// TODO: Follow up with Thomas
                    const data = doc.data();
                    data.id = doc.id;
                    data.editing = false;
                    data.changed = false;
                    data.parentCatID = doc.data().parentCatID ?? 'xSu8bBuH6RbVsPdE7SWs'; // this cat ID is for "Other" cat
                    this.filterTags.push(data);
                });
            });
            setTimeout(() => {
                this.loadFilterCategories();
                this.loadMicroHelperTags();
            }, 800);
        },

        async loadMicroHelperTags() {
            const querySnapshot = await getDocs(collection(getFirestore(), 'microHelperTags'));
            this.microHelperTags = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                data.id = doc.id;
                this.microHelperTags.push(data);
            });
        },

        loadFilterCategories() {
            const q = query(collection(getFirestore(), 'tagCategories'), orderBy('index', 'asc'));
            onSnapshot(q, (querySnapshot) => {
                this.filterCategories = [];
                querySnapshot.forEach((doc) => {
                    // const data = doc.data();  /// TODO: Follow up with Thomas
                    const data = doc.data();
                    data.id = doc.id;
                    data.editing = false;
                    data.changed = false;
                    data.tags = this.filterTags.filter((n) => n.parentCatID == doc.id);
                    this.filterCategories.push(data);
                });
            });
        },

        //////
        async incrementFleetCart(companyID, lineItem) {
            const updateRef = doc(getFirestore(), 'companies', companyID);
            await updateDoc(updateRef, {
                currentLocalOrder: arrayUnion(lineItem)
            });
        },

        async updateFleetCart(companyID, updatedCurrentOrder) {
            const updateRef = doc(getFirestore(), 'companies', companyID);
            await updateDoc(updateRef, {
                currentLocalOrder: updatedCurrentOrder
            });
        },

        async incrementUserCart(userID, lineItem) {
            const updateRef = doc(getFirestore(), 'userdata', userID);
            await updateDoc(updateRef, {
                currentLocalOrder: arrayUnion(lineItem)
            });
        },

        async updateUserCart(userID, updatedCurrentOrder) {
            const updateRef = doc(getFirestore(), 'userdata', userID);
            await updateDoc(updateRef, {
                currentLocalOrder: updatedCurrentOrder
            });
        }
    }
});
