<template>
    <div class="item">
        <div class="details">
            <slot></slot>

            <div class="vessel-button" :class="{ inverted: inverted }" @click="otherAction()">
                <!-- <div class="round-button-letters">
                    <span>{{ makeLetters(sentVessel.vesselName) }}</span>
                </div> -->
                <div class="avatar" v-if="sentVessel && sentVessel.avatarUrl && sentVessel.avatarUrl != ''">
                    <img :src="sentVessel.avatarUrl" :alt="sentVessel.vesselName" />
                </div>
                <div v-else class="round-button-letters">
                    <span v-if="sentVessel && sentVessel.vesselName">{{ makeLetters(sentVessel.vesselName) }}</span>
                </div>
                <div class="content-box">
                    <h4>{{ sentVessel.vesselName }}</h4>
                    <span>{{ sentVessel.role }}</span>
                </div>
                <div class="flag"><font-awesome-icon icon="exclamation-triangle" /></div>
            </div>
        </div>
    </div>
</template>

<script>
import { currentDataStore } from '@/stores/currentdata';
export default {
    setup() {
        const current_data = currentDataStore();
        return {
            current_data: current_data
        };
    },
    props: {
        sentVessel: Object,
        inverted: Boolean
    },
    mounted() {},
    methods: {
        // toVesselPage() {
        //     return;
        //     this.current_data.setCurrentVesselTab('1');
        //     this.current_data.setCurrentVessel(this.sentVessel.vesselID);
        //     this.$router.push({
        //         path: '/manage-asset'
        //     });
        // },
        makeLetters(vesselName) {
            let res = vesselName.split(' ');
            if (res.length > 1) {
                return res[0].substring(0, 1) + res[1].substring(0, 1);
            } else {
                return res[0].substring(0, 1);
            }
        },
        otherAction() {
            let result = {
                asset: this.sentVessel
            };
            this.$emit('clicked', result);
        }
    },
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
.item {
    margin-top: 0;
    display: flex;
    text-align: left;
}

.details {
    flex: 1;
}

.round-button-letters {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    text-align: center;
    position: relative;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: steelblue;
        font-weight: bold;
        text-transform: uppercase;
    }
}

h3 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.4rem;
    color: var(--color-heading);
}

.vessel-button {
    margin-bottom: 1rem;
    cursor: pointer;
    background: #5aa6e5;
    border-radius: 4px;
    transition: 0.3s;
    display: flex;
    place-items: center;
    padding: 15px;
    // max-width: 400px;

    .avatar {
        width: 44px;
        height: 44px;
        border: 2px solid #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .round-button-letters {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        text-align: center;
        position: relative;
        background: #fff;
        display: block;
        transition: 0.3s;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        span {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            line-height: 1rem;
            font-size: 15px;
            color: steelblue;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
    .content-box {
        text-align: left;
        width: calc(100% - 65px);
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        h4 {
            text-align: left;
            color: #fff;
            margin-bottom: 0;
            // text-transform: uppercase;
            font-weight: bold;
            font-size: 1rem;
            line-height: 17px;
            padding-top: 4px;
            word-break: break-word;
            font-family: 'Open Sans', sans-serif;
            letter-spacing: 0.5px;
        }
        span {
            font-size: 0.9rem;
            color: #fff;
            word-break: break-word;
        }
    }
    &:hover {
        background: cadetblue;
    }
    &.inverted {
        border: 1px solid #dadada;
        color: #5aa6e5;
        background: #fff;
        .round-button-letters {
            background: #5aa6e5;
            color: #fff;
            h4,
            span {
                color: #fff;
            }
        }
        .content-box {
            h4 {
                color: #5aa6e5;
            }
            span {
                color: mediumseagreen;
            }
        }
        &:hover {
            // border-color: #e6f0f4;
            // background: #e6f0f4;
        }
    }
    .flag {
        position: absolute;
        top: 5px;
        right: 10px;
        color: #ffdb00;
    }
}

@media screen and (max-width: 1024px) {
    .item {
        margin-top: 0;
        padding: 0;
    }
}
</style>
