<template>
    <div>
        <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showModal }">
            <div class="modal-background" @click="attemptClose"></div>
            <div class="modal-card" :class="{ 'is-fullwidth': isFullWidth }">
                <header class="modal-card-head" :class="theme">
                    <p class="modal-card-title">{{ title }}</p>
                    <button class="delete" aria-label="hasSeenNotice" @click="attemptClose"></button>
                </header>

                <section class="modal-card-body">
                    <slot>Loading content...</slot>
                </section>
                <footer class="modal-card-foot">
                    <div class="footer-container">
                        <div>
                            <button v-if="showCancel" class="button" @click="attemptClose">
                                {{ cancelText ? cancelText : 'Close' }}
                            </button>
                            <button
                                v-if="showComplete"
                                class="button"
                                :class="[theme, { 'is-loading': showLoading }]"
                                @click="submit"
                                :disabled="disabled || showLoading">
                                {{ completeText ? completeText : 'Finish' }}
                            </button>
                            <slot name="extra-button"></slot>
                        </div>
                        <slot name="steps">
                            <div><!-- Bejai note: this is a filler in case steps isn't provided --></div>
                        </slot>
                        <div class="confirmClose">
                            <div class="red" v-if="confirmCloseBool"><font-awesome-icon icon="triangle-exclamation" /> Unsaved Changes</div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>

        <!-- Confirm close without saving -->
        <ConfirmDialog
            v-if="showConfirmClose"
            title="Leave without saving?"
            theme="is-danger"
            submitText="Yes, leave"
            cancelText="Go back"
            @sendResult="close"
            @cancel="cancelClose">
            <p class="has-text-centered">
                You have unsaved changes. These will be lost if you leave without saving. Are you sure you wish to proceed?
            </p>
        </ConfirmDialog>
    </div>
</template>

<script>
import { currentDataStore } from '@/stores/currentdata';
import { loggedInUserStore } from '@/stores/userdata';
// import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, doc, addDoc, getDocs, onSnapshot, orderBy, where, query, collection, updateDoc } from 'firebase/firestore';
import ConfirmDialog from '@/components/modals/ConfirmDialog.vue';

export default {
    setup() {
        const current_data = currentDataStore();
        const user_store = loggedInUserStore();
        const db = getFirestore();
        return {
            current_data: current_data,
            user_store: user_store,
            db: db
        };
    },

    props: {
        title: String,
        theme: String,
        fullWidth: Boolean,
        cancelText: String,
        completeText: String,
        showCancel: Boolean,
        showComplete: Boolean,
        disabled: Boolean,
        showLoading: Boolean,
        confirmCloseBool: Boolean
    },

    components: {
        ConfirmDialog
    },

    mounted() {},

    data() {
        return {
            showModal: true,
            showConfirmClose: false,
            isFullWidth: this.fullWidth ?? false
        };
    },

    computed: {},

    methods: {
        attemptClose() {
            if (this.confirmCloseBool) {
                this.showConfirmClose = true;
            } else {
                this.close();
            }
        },
        cancelClose() {
            this.showConfirmClose = false;
        },

        close() {
            this.showModal = false;
            let result = {
                answer: 'Modal closed',
                modal: false
            };
            this.$emit('closed', result);
        },
        submit() {
            // this.showModal = false;
            let result = {
                answer: 'Action submitted',
                modal: false
            };
            this.$emit('submit', result);
        }
    }
};
</script>

<style lang="scss" scoped>
.modal {
    .modal-card {
        &.is-fullwidth {
            max-width: 1000px;
            height: 90%;
            height: 90vh;
        }
    }
}
.modal-card .modal-card-head p.modal-card-title {
    color: white;
    text-transform: none;
    font-weight: normal;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.confirmClose {
    width: 144px;
}
</style>
