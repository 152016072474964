<template>
    <div class="modal" v-bind:class="{ 'is-active': showModal }">
        <div class="modal-background" @click="close"></div>
        <div class="modal-content">
            <div class="box">
                <h3>{{ title }}</h3>
                <slot></slot>

                <div v-if="answerArray.length > 0">
                    <div class="form-field" v-for="(field, index) in sentForm" :key="field.label">
                        <div class="text-field" v-if="field.type == 'text'">
                            <label :class="{ required: field.required && answerArray[index].answer.length == 0 }">{{ field.label }}</label>
                            <input class="input" type="text" :placeholder="field.placeholder" v-model="answerArray[index].answer" required />
                        </div>
                        <div class="dropdown-field" v-if="field.type == 'dropdown'">
                            <label :class="{ required: field.required && answerArray[index].answer.length == 0 }">{{ field.label }}</label>
                            <div class="select">
                                <select v-model="answerArray[index].answer">
                                    <option disabled value="">Choose one</option>
                                    <option v-for="(option, idx) in field.options" :value="option.val" :key="`option-${idx}`">
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="spacer s60"></div>

                <div class="buttons">
                    <button class="button is-cancel" @click="close()">Cancel</button>
                    <button :disabled="fieldsMissing > 0" class="button" :class="theme" @click="save()">
                        {{ submitText ? submitText : 'Done' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        theme: String,
        sentForm: Object,
        submitText: String
    },
    mounted() {
        this.sentForm.forEach((item) => {
            this.returnFleetData.push({ fieldName: item.fieldName, answer: '' });
            this.answerArray.push({
                answer: '',
                required: item.required
            });
        });
        setTimeout(() => {
            this.mounted = true;
        }, 500);
    },
    computed: {
        fieldsMissing() {
            return this.answerArray
                .filter((n) => n.required)
                .reduce((total, t, idx) => {
                    let x = t.answer.length > 0 ? 0 : 1;
                    return total + x;
                }, 0);
        }
    },
    methods: {
        close() {
            this.showModal = false;
            let result = {
                hasResult: false,
                returnData: {}
            };
            this.$emit('sendResult', result);
            this.returnFleetData = [];
        },

        save() {
            this.showModal = false;
            let result = {
                hasResult: true,
                returnData: {}
            };
            this.returnFleetData.map((field, index) => {
                result.returnData[field.fieldName] = this.answerArray[index].answer;
            });
            this.$emit('sendResult', result);
            this.returnFleetData = [];
        }
    },
    data() {
        return {
            showModal: true,
            returnFleetData: [],
            answerArray: [],
            mounted: false
        };
    }
};
</script>

<style lang="scss" scoped>
h3 {
    font-size: 1.3rem;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 1px;
}
.form-field {
    margin-bottom: 1.2rem;
    label {
        display: block;
        margin: 5px 0 8px;
        font-weight: bold;
        font-size: 1rem;
        &.required {
            color: red;
        }
    }
    input,
    textarea {
        padding: 15px;
        height: auto;
        margin-top: 0;
    }
    .select {
        // display: block;
        height: auto;
        // padding: 15px;
        // padding-right: 3rem;
        &:after {
            top: 25px;
        }
        select {
            display: block;
            height: auto;
            padding: 12px;
            padding-right: 3rem;
        }
    }
}
.buttons {
    margin-bottom: 12px;
}
// .modal-content, .modal-card {
//     width: 95%;
//     max-width: 1400px;
//     &:not(.fullwidth) {
//         max-width: 640px;
//     }
// }

@media (min-width: 1024px) {
}
</style>
