<template>
    <!-- <RouterView v-if="userDataIsLoaded" /> -->
    <RouterView v-if="(isLoggedIn && userDataIsLoaded) || !isLoggedIn" />
    <!-- <vue-element-loading :active="isActive" :is-full-screen="true"/>
    <div class="hello">HELLO</div> -->
    <vue-element-loading :active="isLoggedIn && !userDataIsLoaded" spinner="bar-fade-scale" is-full-screen text="Loading OffshoreSMS..." />
</template>

<script lang="ts">
import { loggedInUserStore } from '@/stores/userdata';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export default {
    setup() {
        const user_store = loggedInUserStore();
        return {
            user_store: user_store
        };
    },
    components: {},
    mounted() {
        onAuthStateChanged(getAuth(), (user) => {
            if (user) {
                this.isLoggedIn = true;
                this.user_store.setUserID(user.uid);
                this.user_store.setUserData();
                this.user_store.setFirebaseUser(user);
                this.user_store.setUserIsLoggedIn(true);
            } else {
                // Do we even need this??? I think router navigation guard covers it!
                this.isLoggedIn = false;
                this.user_store.setFirebaseUser({});
                this.user_store.logOutUser();
                this.user_store.setUserID('');
                this.user_store.setUserIsLoggedIn(false);
            }
        });
    },
    computed: {
        userData() {
            return this.user_store.getUserData;
        },
        userDataIsLoaded() {
            return this.user_store.getUserDataIsLoaded;
        },
        userIsLoggedIn() {
            return this.user_store.getUserIsLoggedIn;
        }
    },

    data() {
        return {
            isLoggedIn: false
        };
    },

    methods: {}
};
</script>

<style lang="scss">
@import 'bulma/css/bulma.css';
@import 'bulma-switch/dist/css/bulma-switch.min.css';
@import 'bulma-checkradio/dist/css/bulma-checkradio.min.css';
@import 'bulma-pricingtable/dist/css/bulma-pricingtable.min.css';
@import '@/assets/base.css';
@import '@/assets/general.scss';
@import '@/assets/buttons.scss';
@import '@/assets/tables.scss';
@import '@/assets/forms.scss';

#app {
    width: 100%;
    /* max-width: 1400px; */
    margin: 0;
    /* padding: 2rem 2rem 10rem; */
    font-weight: normal;
    padding: 0 0 0 1rem;
}

header {
    line-height: 1.5;
    max-height: 100vh;
}

.logo {
    display: block;
}

a,
.green {
    text-decoration: none;
    color: hsla(160, 100%, 37%, 1);
    transition: 0.4s;
}

@media (hover: hover) {
    /* a:hover {
    background-color: hsla(160, 100%, 37%, 0.2);
  } */
}
.hello {
    display: flex;
    z-index: 999999999999;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
}

nav {
    width: 100%;
    /* font-size: 12px; */
    text-align: center;
    margin-bottom: 1rem;
}

nav a.router-link-exact-active {
    color: var(--color-text);
}

nav a.router-link-exact-active:hover {
    background-color: transparent;
}

nav a {
    display: inline-block;
    padding: 0 1rem;
    border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
    border: 0;
}

@media (min-width: 1024px) {
    body {
        display: flex;
    }

    header {
        display: flex;
        place-items: center;
        padding-right: calc(var(--section-gap) / 2);
    }

    header .wrapper {
        display: flex;
        place-items: flex-start;
        flex-wrap: wrap;
    }

    nav {
        text-align: center;
        font-size: 1rem;
        padding: 1rem 0;
        margin-top: 0;
    }
}

@media (max-width: 479px) {
    #app {
        padding: 2rem 1rem;
    }
}
</style>
