<template>
    <div>
        <div class="hamburger-wrapper" @click="setShowMobileNav()">
            <div class="hamburger-icon" id="icon">
                <div class="icon-1" id="a" :class="{ a: showMobileNav }"></div>
                <div class="icon-2" id="b" :class="{ c: showMobileNav }"></div>
                <div class="icon-3" id="c" :class="{ b: showMobileNav }"></div>
                <div class="clear"></div>
            </div>
        </div>
        <!-- <div class="">
            <transition name="slide-fade">
                <div v-if="showMobileNav" class="dropmenu">
                    <ul>
                        <li>
                            <router-link to="my-profile">
                                <span><font-awesome-icon icon="user-cog" /></span> &nbsp;My Profile
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/">
                                <span><font-awesome-icon icon="home" /></span> &nbsp;My Home Page
                            </router-link>
                        </li>
                        <li>
                            <span><font-awesome-icon icon="life-ring" /></span> &nbsp;Help & Support
                        </li>
                        <li>
                            <span><font-awesome-icon icon="book" /></span> &nbsp;Documentation
                        </li>
                        <li>
                            <span><font-awesome-icon icon="info-circle" /></span> &nbsp;Useful Links
                        </li>
                        <li style="text-align: right"><div class="version">Web Version 1.3.17</div></li>
                    </ul>
                </div>
            </transition>
        </div> -->
    </div>
</template>

<script>
import { currentDataStore } from '@/stores/currentdata';

export default {
    setup() {
        const current_data = currentDataStore();
        return {
            current_data: current_data
        };
    },

    unmounted() {
        this.current_data.setMobileNav(false);
    },
    computed: {
        showMobileNav() {
            return this.current_data.getShowMobileNav;
        }
    },
    methods: {
        setShowMobileNav() {
            this.current_data.setMobileNav(!this.showMobileNav);
        }
    }
};
</script>

<style lang="scss" scoped>
.hamburger-wrapper {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 42px;
    margin: 0 7px;
    cursor: pointer;
    .hamburger-icon {
        position: absolute;
        height: 42px;
        width: 42px;
        top: 10%;
        left: 3%;
        z-index: 1000;
        cursor: pointer;
        border-radius: 50%;
        transition: all 0.2s ease-in-out;
        background: steelblue;
        // background: green;
        // width: auto;
        // height: 50px;
        // overflow: hidden;
        &:hover {
            // .icon-1 {
            //   transform: rotate(40deg);
            // }
            // .icon-3 {
            //   transform: rotate(-40deg);
            // }
            // .icon-2 {
            //   opacity: 0;
            // }
            transform: scale(1.1);
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        }
    }

    .icon-1.a {
        transform: rotate(40deg);
    }
    .icon-3.b {
        transform: rotate(-40deg);
    }
    .icon-2.c {
        opacity: 0;
    }
}

@keyframes slideIn {
    0% {
        width: 0%;
        // transform: scale(.3);
        opacity: 0;
    }

    100% {
        width: 50%;
        // transform:scale(1);
        opacity: 1;
    }
}

.icon-1,
.icon-2,
.icon-3 {
    position: absolute;
    left: 23%;
    top: 48%;
    width: 22px;
    height: 2px;
    background-color: #fff;
    transition: all 400ms cubic-bezier(0.84, 0.06, 0.52, 1.8);
}

.icon-1 {
    transform: translateY(-8px);
    animation-delay: 100ms;
}

.icon-3 {
    transform: translateY(8px);
    animation-delay: 250ms;
}
// .dropmenu {
//     position: fixed;
//     border-radius: 8px;
//     padding: 2rem;
//     background: steelblue;
//     z-index: 99999;
//     top: 65px;
//     right: 12px;
//     min-width: 280px;
//     ul {
//         li {
//             color: #fff;
//             border-bottom: 1px dashed #77a6cd;
//             padding: 15px 0;
//             cursor: pointer;
//             transition: 0.25s;
//             span {
//                 transition: 0.25s;
//                 display: inline-block;
//             }
//             &:hover {
//                 span {
//                     transform: scale(1.2);
//                     color: aqua;
//                 }
//             }
//             a {
//                 color: #fff;
//                 text-decoration: none;
//             }
//         }
//     }
// }
</style>
